import React, { ChangeEvent, ReactEventHandler, useState } from 'react'
import {
  Box,
  FormLabel,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
} from '@mui/material'
import { Controller } from 'react-hook-form'
import CustomPopover from './CustomPopover'
import Images from '../Utils/images'
import { notify } from '../Utils/toastify'
import { calculateFileSize } from '../helpers/functions'
import { AWS_FOLDER_PATH, S3_BUCKET, reelBucket } from '../Utils/AwsService'
import { generateRandomString } from '../Utils/helperFns'
interface Props {
  id: string
  label?: string
  placeholder?: string
  name: string
  helperText?: string
  error?: boolean
  disabled?: boolean
  // value?: string | number;
  defaultValue?: string | number
  variant?: 'outlined' | 'standard' | 'filled' | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  value?: string
  startAdornment?: React.ReactElement | null
  endAdornment?: React.ReactElement | null
  control?: null | any
  tooltip?: boolean
  popoverContent?: React.ReactElement | null
  endAdornmentIcon?: React.ReactElement
  setValue?: any
  sizeLimit?: number 
  sizeLimitError?: string
  showCard?: boolean
  s3Upload?: boolean
  s3fileUrl?: string
  setS3FileUrl?: (url: string) => void
  requiredField?: boolean
  schema?: any
}

const FileInput: React.FC<Props> = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    helperText,
    error,
    disabled,
    defaultValue,
    variant,
    value,
    onBlur,
    startAdornment,
    endAdornment,
    onChange,
    control,
    tooltip,
    popoverContent,
    endAdornmentIcon,
    setValue,
    sizeLimit = 3 * 1024 * 1024,
    showCard = false,
    sizeLimitError = 'File size exceed',
    s3Upload = false,
    setS3FileUrl,
    s3fileUrl,
    requiredField,
    schema,
  } = props
  const [progress, setProgress] = useState(0)

  const imageRef = React.useRef<HTMLInputElement>(null)
  const handleClick = (e: any) => {
    if (imageRef && imageRef.current) {
      imageRef.current.click()
    }
  }

  const handleUpload = async (file: File) => {
    const fileURl = AWS_FOLDER_PATH + generateRandomString(5) + file.name
    const params = {
      ACL: 'private',
      Body: file,
      Bucket: S3_BUCKET,
      Key: fileURl,
    }

    await reelBucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send((err, data) => {
        if (data && setS3FileUrl) {
          setS3FileUrl(fileURl)
        }
        if (err) {
          notify('Error while uploading, Please try again.', 'error')
          setValue(name, undefined)
        }
      })
  }

  const handleDelete = () => {
    if (s3fileUrl) {
      const params = {
        Bucket: S3_BUCKET || '',
        Key: s3fileUrl,
      }
      reelBucket.deleteObject(params, (err, data) => {})
      setS3FileUrl && setS3FileUrl('')
    }
    setValue(name, undefined)
  }

  return (
    <Box>
      <FormLabel className="formLabel">
        {label} {requiredField ? <sup>*</sup> : null}
        {tooltip ? (
          <>
            <CustomPopover
              id=""
              children={popoverContent}
              arrow
              anchorOrigin_vertical="top"
              anchorOrigin_horizontal="center"
              transformOrigin_vertical="bottom"
              transformOrigin_horizonral="center"
            />
          </>
        ) : null}
      </FormLabel>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <div className="fileSelection">
                <input
                  name="picture"
                  type="file"
                  ref={imageRef}
                  aria-hidden="true"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    console.log("wwww")
                    if (e.target.files) {
                      if (s3fileUrl) {
                        handleDelete()
                      }
                      if (e.target.files[0].size <= sizeLimit) {
                        if (setValue) {
                          setValue(name, e.target.files[0])
                        }
                        if (schema) {
                          const file = e.target.files[0]
                          try {
                            await schema.validateAt(name, { [name]: file })
                          } catch (error: any) {
                            error &&
                              error.message &&
                              notify(error.message, 'error')
                            console.error('Error uploading files:', error)
                            setValue(name, null)
                            return
                          }
                        }

                        if (s3Upload) {
                          handleUpload(e.target.files[0])
                        }
                      } else {
                        notify(sizeLimitError, 'error')
                        return
                      }
                    }
                  }}
                  accept=".jpg , .jpeg , .png ,"
                />
                <TextField
                  id={id}
                  type="text"
                  helperText={helperText}
                  variant={variant}
                  error={error}
                  placeholder={placeholder}
                  disabled={disabled}
                  color="primary"
                  size="small"
                  {...field}
                  value={field.value ? field.value.name : ''}
                  // onChange={onChange}
                  // onClick={handleClick}
                  fullWidth
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  onClick={handleClick}
                  InputProps={{
                    startAdornment,
                    autoComplete: 'off',
                    endAdornment: endAdornmentIcon,
                    readOnly: true,
                  }}
                  className="fileInputField"
                />
                {field.value && showCard && (
                  <div className="fileProgress">
                    <div className="progressInfo">
                      <div className="fileIc">
                        <img src={Images.FILE_ICON} alt="File" />
                      </div>
                      <div className="doc_name">
                        <h4>{field.value ? field.value.name : ''}</h4>
                        <p>{calculateFileSize(field.value)}</p>
                      </div>
                      <IconButton className="icButton" onClick={handleDelete}>
                        <img src={Images.DELETE_IC} alt="Delete" />
                      </IconButton>
                    </div>
                    <div className="linearProgress">
                      <LinearProgress variant="determinate" value={progress} />
                      <p>{progress}%</p>
                    </div>
                  </div>
                )}
              </div>
            )
          }}
        />
      ) : (
        <>
          <input
            name="picture"
            type="file"
            ref={imageRef}
            aria-hidden="true"
            style={{ display: 'none' }}
            onChange={(e: any) => {}}
            accept=".jpg , .jpeg , .png , .svg , .webp"
          />
          <TextField
            id={id}
            size="small"
            name={name}
            type="text"
            helperText={helperText}
            variant={variant}
            error={error}
            placeholder={placeholder}
            disabled={disabled}
            color="primary"
            // value={value || ''}
            // onChange={onChange}
            defaultValue={defaultValue}
            fullWidth
            onClick={handleClick}
            InputProps={{
              startAdornment,
              autoComplete: 'off',
              endAdornment :endAdornmentIcon,
              readOnly: true,
            }}
            className="fileInputField"
            
          />
        </>
      )}
    </Box>
  )
}

FileInput.defaultProps = {
  placeholder: '',
  error: false,
  disabled: false,
  variant: 'outlined',
  startAdornment: null,
  endAdornment: null,
}

export default FileInput
