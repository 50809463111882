import { Box, Grid } from '@mui/material'
import AutocompleteSelect from '../../../Components/AutocompleteSelect'
import CustomButton from '../../../Components/CustomButton'
import InputField from '../../../Components/Input'
import DateInput from '../../../Components/DateInput'
import Images from '../../../Utils/images'
import FileInput from '../../../Components/FileInput'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { putApiCall } from '../../../api/methods'
import endPoints from '../../../api/endpoint'
import { notify } from '../../../Utils/toastify'
import { useState } from 'react'
import { MaxFileSizeLimit, ErrorMsg } from '../../../helpers/contants'
import { RootState, useAppSelector } from '../../../redux/store'
import { useDispatch } from 'react-redux'
import { updateStep } from '../../../redux/authSlice'

export const LegalDocSchema = yup
  .object({
    documentType: yup.string().required('Document Type is required'),
    document: yup
      .mixed()
      .test(
        'fileType',
        'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
        (value: any) => {
          if (!value) return true // Allow empty input
          const supportedFileTypes = [
            'png',
            'jpg',
            'jpeg',
            'pdf',
            'doc',
            'docx',
          ]
          const fileType = value.type.split('/')[1] // Extract file extension from MIME type
          return supportedFileTypes.includes(fileType)
        }
      )
      .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
        if (!value) return true // Allow empty input

        return value.size <= MaxFileSizeLimit
      })
      .required('Document is required'),
    documentNumber: yup
      .string()
      .required('Document Number is required')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    countryOfIssue: yup.object().required('Country of issue is required'),
    dateOfExp: yup.string().required('Date of expire is required'),
  })
  .required()

const fileSchema = yup.object({
  document: yup
    .mixed()
    .test(
      'fileType',
      'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
      (value: any) => {
        if (!value) return true // Allow empty input
        const supportedFileTypes = ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx']
        const fileType = value.type.split('/')[1] // Extract file extension from MIME type
        return supportedFileTypes.includes(fileType)
      }
    )
    .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
      if (!value) return true // Allow empty input
      return value.size <= MaxFileSizeLimit
    }),
})
interface Props {
  changeStep: (step: number) => void
}

export default function LegalDocuments({ changeStep }: Props) {
  const dispatch = useDispatch()
  const { countryList } = useAppSelector((state: RootState) => state.common)
  const [load, setLoad] = useState<boolean>(false)

  const [s3fileUrl, sets3FileUrl] = useState<string>('')
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(LegalDocSchema),
    mode: 'all',
  })

  const onSubmit = (data: any) => {
    const payload = data

    payload['type'] = 'LEGAL_DOC'
    payload['documentUrl'] = s3fileUrl
    payload['countryOfIssue'] = payload['countryOfIssue'].name
    delete payload['document']
    putApiCall(
      endPoints.businessRegister,
      payload,
      (s: any) => {
        const {
          data: { statusCode },
        } = s
        if (statusCode && statusCode === 202) {
          notify('Legal Document saved successfully. ', 'success')
          dispatch(updateStep('BILLING_DETAILS'))
          changeStep(3)
        }
      },
      (e: any) => {
        setLoad(false)
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error')
        } else {
          notify(null, 'error')
        }
      }
    )
  }

  return (
    <div className="businessInfo_step contactInfo">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <FileInput
                requiredField
                id="attachment"
                label="Attachment"
                name="document"
                placeholder="Attach document"
                error={!!errors['document']}
                control={control}
                showCard={true}
                sizeLimit={MaxFileSizeLimit}
                sizeLimitError="Max. 3 MB file size allowed"
                endAdornmentIcon={
                  <img src={Images.ATTACHMENT} alt="attach file" />
                }
                helperText={
                  !!errors['document'] ? `${errors['document'].message}` : ''
                }
                setValue={setValue}
                s3Upload={true}
                s3fileUrl={s3fileUrl}
                setS3FileUrl={sets3FileUrl}
                requiredField
                schema={fileSchema}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <AutocompleteSelect
                requiredField
                id="document_type"
                labelText="Document Type"
                placeHolderText="Select Document Type"
                defaultValue="Select Document Type"
                name="documentType"
                control={control}
                error={!!errors['documentType']}
                setValue={setValue}
                helperText={
                  !!errors['documentType']
                    ? `${errors['documentType'].message}`
                    : ''
                }
                options={['Type 1', 'Type 2', 'Type 3', 'Type 4']}
                requiredField
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                requiredField
                id="document_number"
                label="Document number"
                name="documentNumber"
                placeholder="Enter document number"
                error={!!errors['documentNumber']}
                helperText={
                  !!errors['documentNumber']
                    ? errors['documentNumber'].message
                    : ``
                }
                control={control}
                requiredField
              />
            </Grid>
          </Grid>
          <div className="formLabel">
            <h3>Country of issue</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <AutocompleteSelect
                requiredField
                id="Country"
                labelText="Country"
                placeHolderText="Select country"
                name="countryOfIssue"
                control={control}
                error={!!errors['countryOfIssue']}
                setValue={setValue}
                helperText={
                  !!errors['countryOfIssue']
                    ? `${errors['countryOfIssue'].message}`
                    : ''
                }
                isCountryField={true}
                options={countryList}
                defaultValue=""
                requiredField
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <DateInput
                requiredField
                disabled={false}
                label="Expiry Date"
                minDate={new Date('1900-01-01').toISOString()}
                name="dateOfExp"
                formProps={{ control }}
                error={!!errors['dateOfExp']}
                helperText={
                  !!errors['dateOfExp'] && (
                    <p className="helper-error">
                      {errors['dateOfExp'].message}
                    </p>
                  )
                }
                requiredField
              />
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <FileInput
                id="date_of_expiry"
                label="Date of Expiry"
                name="date_of_expiry"
                placeholder="Add attachment"
                // error={}
                value=""
                // control={control}
                endAdornmentIcon={
                  <img src={Images.ATTACHMENT} alt="attach file" />
                }
              />
            </Grid> */}
          </Grid>
        </Box>
        <Box className="buttonContainer">
          <CustomButton
            size="large"
            variant="contained"
            text="Submit"
            showIcon={false}
            width="100%"
            type="submit"
            id="login"
            disabled={!isValid}
            loading={load}
          />
        </Box>
      </form>
    </div>
  )
}
