import { createSlice } from '@reduxjs/toolkit'
import endPoints from '../api/endpoint'
import store from './store'
import { deleteApiCall, getApiCall } from '../api/methods'
import { notify } from '../Utils/toastify'
import { CommonState } from '../types'

const initialState: CommonState = {
  countryList: [],
  departmentList: [],
  categories: [],
  loading: false,
  page: 1,
  searchedLocations: [],
  filterapplied: false
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setData: (state, action) => {
      const {
        payload: { type, value },
      }: any = action
      if (type === 'country') {
        state.countryList = value
      } else if (type === 'department') {
        state.departmentList = value
      } else if (type === 'categories') {
        state.categories = value
      }
    },
    updatePage: (state, action) => {
      const { payload }: any = action
      state.page = payload
    },
    setCommonDataLoader: (state, action) => {
      const { payload }: any = action
      state.loading = payload
    },
    updateSearchedLocations: (state, action) => {
      const { payload }: any = action
      state.searchedLocations = payload
    },
    setFilterApplied: (state, action) => {
      state.filterapplied = action.payload
    },
  },
})

export const { setData, setCommonDataLoader, updatePage, updateSearchedLocations, setFilterApplied } = commonSlice.actions

export default commonSlice.reducer

export const getAndSetData = (type: string, query: string = '') => {
  const apiEndpoint =
    type === 'country'
      ? endPoints.common.countryList
      : type === 'department'
      ? endPoints.common.deparmentList
      : endPoints.common.categories

  store.dispatch(setCommonDataLoader(true))
  getApiCall(
    `${apiEndpoint}${query}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        store.dispatch(setData({ type: type, value: data.data }))
      }
      store.dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      console.log(e)
      store.dispatch(setCommonDataLoader(false))
      notify(`Error while fetching ${type} list`, 'error')
    },
    type === 'country'
      ? { Authorization: `Basic ${btoa('reel:reel@123')}` }
      : {}
  )
}
export const updatePaginationPage = (page: number) => {
  const { dispatch } = store
  dispatch(updatePage(page))
}

export async function fetchLocationByText(location: string) {
  const { dispatch } = store
  console.log(location, 'loc')

  const apiKey = process.env.GOOGLE_API_KEY
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${apiKey}`
  )
  response.json().then((data) => {
    if (data.results) {
      dispatch(updateSearchedLocations(data.results))
      console.log(data)
    }
  })
}

