import AWS from 'aws-sdk'
import {
  decryptDataWithKey,
  generateRandomString,
  keyPhrase,
} from './helperFns'
import { s3Url } from './constantData'
import { notify } from './toastify'

export const S3_BUCKET = process.env.S3_BUCKET_NAME
const REGION = process.env.S3_REGION
export const AWS_FOLDER_PATH = process.env.FOLDER_PATH
AWS.config.update({
  accessKeyId: decryptDataWithKey(process.env.AWS_ACCESS_KEY_ID, keyPhrase),
  secretAccessKey: decryptDataWithKey(
    process.env.AWS_SECRET_ACCESS_KEY,
    keyPhrase
  ),
})

export const reelBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

interface UploadedFile {
  url: string
  name: string
}

export const uploadToS3 = async (file: File): Promise<UploadedFile> => {
  return new Promise((resolve, reject) => {
    const s3 = reelBucket
    const key = AWS_FOLDER_PATH + `${Date.now()}_${file.name}`
    const params = {
      Bucket: S3_BUCKET || '',
      Key: key,
      Body: file,
      ACL: 'public-read', // Set the ACL as needed
    }

    const options = {
      partSize: 5 * 1024 * 1024, // 5MB parts
      queueSize: 4, // 4 concurrent uploads
    }

    const upload = s3.upload(params, options)

    upload.on('httpUploadProgress', (progress) => {
      const percentage = Math.round((progress.loaded / progress.total) * 100)
      // setUploadProgress(percentage)
    })

    upload
      .promise()
      .then((data) => {
        // console.log('data url s3', data)
        resolve({ url: data.Location, name: file.name })

        // setUploadProgress(null) // Reset progress after successful upload
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
        // setUploadProgress(null) // Reset progress after failed upload
      })
  })
}

// upload func as Vendor pannel
export const commonUploadFn = (
  file: File,
  setProgress: undefined | any = undefined,
  callBack: undefined | ((url: string | null) => void) = undefined
) => {
  const name = generateRandomString(5) + file.name.replaceAll(' ', '-')
  const fileURl = AWS_FOLDER_PATH + name
  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: S3_BUCKET || '',
    Key: fileURl,
    // keyPrefix: keyPrefix,
  }

  const upload = reelBucket
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      setProgress && setProgress(Math.round((evt.loaded / evt.total) * 100))
    })
  upload.send((err, data) => {
    if (data && callBack) {
      callBack(s3Url + fileURl)
    }
    if (err) {
      notify('Error while uploading, Please try again.', 'error')
      callBack && callBack(null)
    }
  })
  return upload
}
