export interface routeTypes {
  name: string
  path: string
  id: number
  isPrivate?: boolean
  Component: React.FC<any>
  pageProp?: { page: string }
}

export type country = {
  code: string
  dial_code: string
  flag: string
  name: string
  _id: string
}
export interface CustomKeys {
  label: string
  value: string
}

export type Department = {
  _id: string
  deptName: string
}

export enum OrderSortKeys {
  updatedAt = 'updatedAt',
  userName = 'userName',
}
export enum BannerSortKeys {
  created = 'created',
 bannerTitle = 'bannerTitle',
}

export enum ConfigurationSortKeys {
 empName = 'empName',
 roleName = 'roleName',
 deptName = 'deptName',
 categoryName = 'categoryName',
 created = 'created',
 universityName ='universityName',
 companyName = 'companyName'
}
export interface CommonState {
  countryList: country[]
  departmentList: Department[]
  categories: any[]
  loading: boolean
  page: number,
  searchedLocations: any[],
  filterapplied: boolean
}
export interface AuthState {
  status: boolean
  userData: null | any
  token: null | string
}
export enum OrderEditOptionValues {
  edit_quantity = 'edit_quantity',
  edit_status = 'edit_status',
  cancel_order = 'cancel_order',
}

export enum OrderRentEditOptionValues {
  approve = 'approve',
  reject = 'reject',
  // edit_quantity = 'edit_quantity',
  edit_status = 'edit_status',
  view_workheet = 'view_workheet',
}
export enum ModalMode {
  edit = 'edit',
  add = 'add',
}
export const EditOptions = {
  // edit_quantity: {
  //   label: 'Edit Quantity',
  //   value: OrderEditOptionValues.edit_quantity,
  // },
  edit_status: {
    label: 'Edit Delivery Status',
    value: OrderEditOptionValues.edit_status,
  },
  cancel_order: {
    label: 'Cancel Order',
    value: OrderEditOptionValues.cancel_order,
  },
}

export const EditRentOptions = {
  approve: {
    label: 'Approve',
    value: OrderRentEditOptionValues.approve,
  },
  reject: {
    label: 'Reject',
    value: OrderRentEditOptionValues.reject,
  },
}

export const EditRentOptionsWithEditAndStatus = {
  view_workheet: {
    label: 'View Worksheet',
    value: OrderRentEditOptionValues.view_workheet,
  },
  // edit_quantity: {
  //   label: 'Edit Quantity',
  //   value: OrderRentEditOptionValues.edit_quantity,
  // },
  edit_status: {
    label: 'Edit Delivery Status',
    value: OrderRentEditOptionValues.edit_status,
  },
}

export interface ProductState {
  loading: boolean
  products: null | any
  error: boolean | string
}

export interface EditedProductOrder {
  mode: OrderEditOptionValues | null
  index: number
  product: any
}
export enum AddToOrder {
  rent = 'rent',
  purchase = 'purchase',
}
export enum ModalMode {
  edit = 'edit',
  add = 'add',
}

export interface CommissionState {
  loading: boolean
  commission: null | any
  error: boolean | string
  vendorsLists:null | any
  type:number
}

export interface OrderState {
  orderDetailsLoader: boolean
  orders: any
  error: boolean | string
  page: number
  filter: null | any
  searchKeywords: string
  productOrderList: [] | any,
  vendorlist: [],
  rentProdctsList: any
  orderDetails: null | any
  setNameList: any
  episodeNameList: any
  locationList: any
  editedItem: EditedProductOrder | null
  cancleOrderModalData: {
    isOrderCancle: boolean
    orderDetails?: any
  } | null
  addToOrderType: null | AddToOrder
  worksheetDetails: any
  deliveryAddressModalData: {
    mode: ModalMode
    itemDetails?: any
  } | null
  listLoader: {
    setNames: boolean
    locations: boolean
    updateAddress: boolean
  }
  rentalModalDetails: {
    state: boolean
    itemDetails: any | null
    formDetails: any
    cartId: string | null
    rentalDetails: any
  }
  quotationShifts: any
  qoutationCartDetails: any
  sortKey: OrderSortKeys;
  sortMode: 1 | -1;
}
export interface NotificationFCM {
  from?: string;
  messageId?: string;
  notification?: {
    body?: string;
    title?: string;
  };
  data?: NotificationData;
}

export interface NotificationData {
  chatId?: string;
  senderImage?: string;
  notificationType?: NotificationType;
  senderId?: string;
  messageType?: string;
  message?: string;
  senderType?: string;
  senderName?: string;
  vendorOrderId?: string;
}
export type NotificationType = 'CHAT' | 'ORDER';
