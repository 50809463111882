import { createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'

interface USERS {
  usersList: any
  userDetails: any
  associateList: any
  loading: boolean
  error: boolean
}

const initialState: USERS = {
  usersList: {},
  userDetails: {},
  associateList: {},
  loading: false,
  error: false,
}
const userManagementSlice:any = createSlice({
  name: 'client management',
  initialState,
  reducers: {
    updateUsersManagement: (state:any, action: any) => {
      return { ...state, ...action.payload }
    },
    resetUsersManagement: (state, action: any) => {
      return initialState
    },
  },
})

export const {
  updateUsersManagement,
  resetUsersManagement,
} = userManagementSlice.actions

export default userManagementSlice.reducer
