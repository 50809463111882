import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'
import {
  ProductionModel,
  ProductionData,
  ProductionListingQueryParameters,
  ProductionDetails,
  ProductionListingFilters,
  ProductionDetailsSetsAndEpisode,
  ProductionEpisodeDetails,
  ProductionSetDetails,
  ProductionSetDetailsByEpisode,
  ProductionStudioListData,
  ProductionDepartmentsData,
  ProductionLocationDetails,
  ProductionLocationDetailsBySet,
  ContactDetails,
  CrewMembersDetail,
  ProductionDepartmentDetails,
  DocumentDetails,
  DepartmentContactDetails,
  DepartmentCrewDetails,
  ProductionSetDetailsByLocation,
} from './production.type'
import { boolean } from 'yup'

const initialState: ProductionModel = {
  productionData: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
  },

  productionListingQueryParameters: {
    pageNo: 1,
    limit: 10,
    sortOrder: 1,
    sortBy: '',
    searchKey: '',
    fromDate: 0,
    toDate: 0,
  },

  productionListingFilters: {
    fromDate: 0,
    toDate: 0,
    productionStatus: '',
    productionType: '',
  },

  productionDetails: {
    _id: '',
    studio: [],
    clientIds: [],
    status: '',
    name: '',
    type: '',
    schedule: [
      {
        _id: '',
        startDate: 0,
        endDate: 0,
        scheduleType: '',
      },
    ],
    address: {
      address: '',
      zipCode: 0,
      city: '',
      state: '',
      country: '',
    },
    image: '',
    description: '',
    documents: [
      {
        _id: '',
        documentUrl: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    userType: '',
    createdAt: '',
    updatedAt: '',
    clientDetails: [],
    studioDetails: [
      {
        _id: '',
        name: '',
      },
      {
        _id: '',
        name: '',
      },
    ],
  },
  productionDetailsSetsAndEpisodeData: [],
  productionDetailsSetsAndEpisode: {
    _id: '',
    studio: [],
    scriptPageNumbers: [],
    name: '',
    schedule: [
      {
        _id: '',
        startDate: 0,
        endDate: 0,
        scheduleType: '',
      },
    ],
    image: '',
    location: {
      _id: '',
      address: '',
    },
    productionId: '',
    episodeId: '',
    sceneDescription: '',
    sceneNumber: 0,
    notes: '',
    documents: [
      {
        _id: '',
        docName: '',
        documentUrl: '',
        docFormat: '',
        documentAddedByUserId: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
    locationCount: 0,
    addedByUser: '',
  },

  productionEpisodeDetails: {
    _id: '',
    studio: [],
    episodeNumber: 0,
    name: '',
    productionId: '',
    startDate: 0,
    endDate: 0,
    image: '',
    associatedPONumber: '',
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
  },

  productionSetDetails: {
    _id: '',
    studio: [],
    scriptPageNumbers: [],
    name: '',
    schedule: [
      {
        _id: '',
        startDate: 0,
        endDate: 0,
        scheduleType: '',
      },
    ],
    image: '',
    location: {
      _id: '',
      address: '',
    },
    productionId: '',
    episodeId: '',
    sceneDescription: '',
    sceneNumber: 0,
    notes: '',
    documents: [
      {
        _id: '',
        docName: '',
        documentUrl: '',
        docFormat: '',
        documentAddedByUserId: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
  },

  productionSetDetailsByEpisode: {
    _id: '',
    studio: [],
    scriptPageNumbers: [],
    name: '',
    schedule: [
      {
        _id: '',
        startDate: 0,
        endDate: 0,
        scheduleType: '',
      },
    ],
    image: '',
    location: {
      _id: '',
      address: '',
    },
    productionId: '',
    episodeId: '',
    sceneDescription: '',
    sceneNumber: 0,
    notes: '',
    documents: [
      {
        _id: '',
        docName: '',
        documentUrl: '',
        docFormat: '',
        documentAddedByUserId: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
    locationCount: 0,
    addedByUser: '',
  },

  productionLocationDetails: {
    _id: '',
    location: {
      type: '',
      coordinates: [],
    },
    studio: [],
    status: '',
    address: {
      _id: '',
      nickname: '',
      locationName: '',
      addressLine1: '',
      addressLine2: '',
      cityTown: '',
      stateProvince: '',
    },
    productionId: '',
    setId: '',
    description: '',
    locationPurpose: '',
    image: {
      _id: '',
      imageUrl: '',
    },
    documents: [
      {
        _id: '',
        docName: '',
        documentUrl: '',
        docFormat: '',
        documentAddedByUserId: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
  },

  productionLocationDetailsBySet: {
    _id: '',
    location: {
      type: '',
      coordinates: [],
    },
    studio: [],
    status: '',
    address: {
      _id: '',
      nickname: '',
      locationName: '',
      addressLine1: '',
      addressLine2: '',
      cityTown: '',
      stateProvince: '',
    },
    productionId: '',
    setId: '',
    description: '',
    locationPurpose: '',
    image: {
      _id: '',
      imageUrl: '',
    },
    documents: [
      {
        _id: '',
        docName: '',
        documentUrl: '',
        docFormat: '',
        documentAddedByUserId: '',
        created: 0,
      },
    ],
    addedByUserId: '',
    createdAt: '',
    updatedAt: '',
  },

  contactDetails: {
    _id: '',
    status: '',
    userType: '',
    userId: '',
    roleName: '',
    profilePicture: '',
    firstName: '',
    lastName: '',
  },

  crewMembersDetail: {
    _id: '',
    status: '',
    userType: '',
    userId: '',
    roleName: '',
    profilePicture: '',
    firstName: '',
    lastName: '',
  },

  departmentContactDetails: {
    _id: '',
    status: '',
    userType: '',
    userId: '',
    roleName: '',
    profilePicture: '',
    firstName: '',
    lastName: '',
  },

  departmentCrewDetails: {
    _id: '',
    status: '',
    userType: '',
    userId: '',
    roleName: '',
    profilePicture: '',
    firstName: '',
    lastName: '',
  },

  documentDetails: {
    _id: '',
    documentAddedByUserId: '',
    entityType: '',
    entityId: '',
    docName: '',
    documentUrl: '',
    docFormat: '',
    created: 0,
  },

  productionDepartmentDetails: {
    _id: '',
    deptImage: '',
    entityId: '',
    deptName: '',
    description: '',
    addedByUserId: '',
    userType: '',
    documents: [],
    created: 0,
  },

  productionSetDetailsByLocation: {
    _id: '',
    status: '',
    name: '',
    addedByUserId: '',
    addedByUser: '',
  },

  productionSetDetailsByEpisodeData: [],
  productionStudioList: [],
  productionDepartmentsData: [],
  productionLocationDetailsBySetData: [],
  crewMembersDetailData: [],
  contactDetailsData: [],
  departmentContactDetailsData: [],
  departmentCrewDetailsData: [],
  documentDetailsData: [],
  productionSetDetailsByLocationData: [],
  productions: {},
  department: {},
  categories: {},
  roles: {},
  loading: false,
  error: false,
  countryList: [],
}
const productionSlice = createSlice({
  name: 'production-management',
  initialState,
  reducers: {
    updateProductionManagement: (
      state: ProductionModel,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },

    setProductionListData: (
      state: ProductionModel,
      action: PayloadAction<ProductionData>
    ) => {
      state.productionData = action.payload
    },

    setProductionListingQueryParameters: (
      state: ProductionModel,
      action: PayloadAction<ProductionListingQueryParameters>
    ) => {
      state.productionListingQueryParameters = action.payload
    },

    setProductionFilters: (
      state: ProductionModel,
      action: PayloadAction<ProductionListingFilters>
    ) => {
      state.productionListingFilters = action.payload
    },

    setProductionDetails: (
      state: ProductionModel,
      action: PayloadAction<ProductionDetails>
    ) => {
      state.productionDetails = action.payload
    },

    setProductionEpisodeDetails: (
      state: ProductionModel,
      action: PayloadAction<ProductionEpisodeDetails>
    ) => {
      state.productionEpisodeDetails = action.payload
    },

    setProductionSetDetails: (
      state: ProductionModel,
      action: PayloadAction<ProductionSetDetails>
    ) => {
      state.productionSetDetails = action.payload
    },

    setProductionLocationDetails: (
      state: ProductionModel,
      action: PayloadAction<ProductionLocationDetails>
    ) => {
      state.productionLocationDetails = action.payload
    },

    setProductionDetailsSetsAndEpisode: (
      state: ProductionModel,
      action: PayloadAction<ProductionDetailsSetsAndEpisode[]>
    ) => {
      state.productionDetailsSetsAndEpisodeData = [...action.payload]
    },

    setProductionStudioList: (
      state: ProductionModel,
      action: PayloadAction<ProductionStudioListData[]>
    ) => {
      state.productionStudioList = [...action.payload]
    },

    setProductionDepartments: (
      state: ProductionModel,
      action: PayloadAction<ProductionDepartmentsData[]>
    ) => {
      state.productionDepartmentsData = [...action.payload]
    },

    setProductionLocationDetailsBySet: (
      state: ProductionModel,
      action: PayloadAction<ProductionLocationDetailsBySet[]>
    ) => {
      state.productionLocationDetailsBySetData = [...action.payload]
    },

    setProductionSetDetailsByEpisode: (
      state: ProductionModel,
      action: PayloadAction<ProductionSetDetailsByEpisode[]>
    ) => {
      state.productionSetDetailsByEpisodeData = [...action.payload]
    },
    setCountryList: (state: ProductionModel, action: PayloadAction<any>) => {
      state.countryList = action.payload
    },

    setContactDetails: (
      state: ProductionModel,
      action: PayloadAction<ContactDetails[]>
    ) => {
      state.contactDetailsData = [...action.payload]
    },

    setDepartmentContactDetails: (
      state: ProductionModel,
      action: PayloadAction<DepartmentContactDetails[]>
    ) => {
      state.departmentContactDetailsData = [...action.payload]
    },

    setDepartmentCrewDetails: (
      state: ProductionModel,
      action: PayloadAction<DepartmentCrewDetails[]>
    ) => {
      state.departmentCrewDetailsData = [...action.payload]
    },

    setCrewMembersDetail: (
      state: ProductionModel,
      action: PayloadAction<CrewMembersDetail[]>
    ) => {
      state.crewMembersDetailData = [...action.payload]
    },

    setDocumentDetails: (
      state: ProductionModel,
      action: PayloadAction<DocumentDetails[]>
    ) => {
      state.documentDetailsData = [...action.payload]
    },

    setProductionSetDetailsByLocation: (
      state: ProductionModel,
      action: PayloadAction<ProductionSetDetailsByLocation[]>
    ) => {
      state.productionSetDetailsByLocationData = [...action.payload]
    },

    setProductionDepartmentDetails: (
      state: ProductionModel,
      action: PayloadAction<ProductionDepartmentDetails>
    ) => {
      state.productionDepartmentDetails = action.payload
    },
  },
})

export const {
  setProductionListData,
  updateProductionManagement,
  setProductionListingQueryParameters,
  setProductionFilters,
  setProductionDetails,
  setCountryList,
  setProductionDetailsSetsAndEpisode,
  setProductionEpisodeDetails,
  setProductionSetDetails,
  setProductionStudioList,
  setProductionSetDetailsByEpisode,
  setProductionDepartments,
  setProductionLocationDetails,
  setProductionLocationDetailsBySet,
  setContactDetails,
  setCrewMembersDetail,
  setProductionDepartmentDetails,
  setDocumentDetails,
  setDepartmentContactDetails,
  setDepartmentCrewDetails,
  setProductionSetDetailsByLocation,
  //   resetVendorManagement,
} = productionSlice.actions

export default productionSlice.reducer
