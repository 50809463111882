const endPoints = {
  login: 'login',
  resetOTP: '/resend-otp',
  forget_password: '/forgot-password',
  resetpassword: 'reset-password',
  change_password: 'change-password',
  updateProfile: 'profile',
  getProfile: 'admin-details',
  template: 'template',
  banner: {
    getBannersList: 'banner-management',
    addBanner: 'client/add-banner',
    editBanner: 'client/edit-banner',
    deleteBanner: 'client/delete-banner',
  },
  chat: {
    getQuote: 'vendor-service/api/v1/quotation/',
    createChat: 'vendor-service/api/v1/user/chat',
    employeeList: 'vendor-service/api/v1/user/chat/vendors',
  },
  clientManagement: {
    addStudio: 'client/add-studio',
    editStudio: 'client/edit-studio',
    addDepartment: 'client/add-department',
    addCompany:'client/add-company',
    editCompany: 'client/edit-company',
    addUniversity:'client/add-university',
    editUniversity: 'client/edit-university',
    editDepartment: 'client/edit-department',
   
    addCategory: 'client/add-category',
    editCategory: 'client/edit-category',
    addRole: 'client/add-role',
    editRole: 'client/edit-role',
    addDegree:'client/add-degree',
    editDegree:'client/edit-degree',
    getallClientManagementList: 'client-management',
  },
  vendorManagement: {
    vendorManagementList: 'vendor-management',
    vendorManagementApproval: 'vendor-approval',
    vendorStatus: 'vendor/status',
  },

  staticContentManagement: {
    getStaticContent: 'client/get-static-content',
    getFAQ: 'client/faq',
    getAddContactInfo: 'client/contact-info',
    addStaticContent: 'client/add-static-content',
    editStaticContent: 'client/edit-static-content',
    addContactInfo: 'client/add-contact-info',
  },

  productionManagement: {
    getProductionList: 'productions',
    createProduction: 'production',
    productionStatus: 'production/status',
    department: 'department',
    updateEpisode: (id: string) => `production/episode/${id}`,
    updateSet: (id: string) => `production/episode/set/${id}`,
  },

  user_management: {
    getUserManagementList: '/user-management',
    user_action: 'admin-user-action',
    updateUser: '/user',
    getAssociateList: '/request_management',
  },
  commissionManagement: {
    commissionManagementList: '/commision',
    addCommissionManagement: '/commision',
    editCommissionManagement: '/commision',
    vendorList:'/commision/vendors',
    deleteCommision:'/commision'
  },

  verify: 'verify-otp',
  businessRegister: 'business/registration',
  common: {
    deparmentList: 'dept-list',
    countryList: 'user/country-list',
    categories: 'category/list',
    studioList: 'studios',
  },
  product: {
    create: '/template',
    list: '/template',
  },

  commonApi: {
    countryList: '/country',
  },
  department: '/depts',
  companyManagement: {
    getUserDetailsWithPhone: 'mobile/user',
    getCompnayList: '/studio',
    updateStatus: 'studio/status',
    create: '/studio',
  },

  roleAndResponsiblity: {
    getRoles: '/role-management',
    updateStatus: 'role/status',
    getStaticRoles: "static-roles"
  },

  requestManagement: {
    getRequest: '/request_management',
    editRequest: '/request',
  },
  order: {
    list: '/orders',
    vendorList: '/orders/vendors',
    cancleOrder: (id: string) => `/orders/${id}/cancel`,
    productItems: 'orders/items',
    updateQuantity: 'orders/',
    updateRentQuantity: (id: string) => `vendor-service/api/v1/order/${id}/qty`,
    updateStatus: 'orders',
    viewWorksheet: (id: string) =>
      `orders/${id}/worksheet`,
    extend_service: (id: string) =>
      `vendor-service/api/v1/order/products/${id}/extend-service`,
    updateShift: (id: string) => `vendor-service/api/v1/order/shifts/${id}`,
    updateStatusShift: (id: string) =>
      `vendor-service/api/v1/order/shifts/${id}/accept-reject`,
    addProducts: (id: string) => `vendor-service/api/v1/order/${id}/add-more`,
    updateAddress: '/orders/',
    rentApproveReject: 'orders/',
    productDetails: 'vendor-service/api/v1/order/products',
    verifyRentalDetails: 'vendor-service/api/v1/chat/service/validate-time',
    createCart: 'vendor-service/api/v1/cart',
    addItemServicesToOrder: `vendor-service/api/v1/order/add-more-service`,
    updateCartOrDelete: `vendor-service/api/v1/cart`,
    quotationCart: 'vendor-service/api/v1/quotation_product/cart',
    quotationShifts: 'vendor-service/api/v1/quotation_cart/shifts',
    quotationCartDetails: 'vendor-service/api/v1/quotation_cart/',
    cartRentalDetailsUpdate: (id: string) =>
      `vendor-service/api/v1/cart/${id}/service-period`,
  },
  // update these notification
  notification: {
    updateDeviceToken: 'notification-service/reel/api/v1/admin/apns/token',
    updateDeviceTokenFCM: 'notification-service/reel/api/v1/admin/fcm/token',
    notifications: 'notification-service/reel/api/v1/vendor/notifications',
  },
}

export default endPoints
