import Images from './images'

export interface Navigation {
  name: string
  path: Array<string>
  icon: any
  icon_active: any
}
export const s3Url =
  process.env.mode === 'preprod'
    ? process.env.IMG_SERVE_URL
    : process.env.S3_URL || ''

export const ORDER_PRODUCT_STATUS: any = {
  PLACED: 'Order Placed',
  PENDING: 'PENDING',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  CANCELLED: 'Canceled',
  DELIVERED: 'Delivered',
  INPROGRESS: 'INPROGRESS',
  RETURNED: 'RETURNED',
  SHIPPED: 'Shipped',
  INTRANSIT: 'In Transit',
  ENDED: 'Service Ended',
  STARTED: 'Service Started',
}
export const navigation: Navigation[] = [
  {
    name: 'Dashboard',
    path: ['/'],
    icon: Images.DASHBOARD,
    icon_active: Images.DASHBOARD_ACTIVE,
  },
  {
    name: 'Vendor Management',
    path: ['/vendors', '/vendor-profile'],
    icon: Images.VENDOR,
    icon_active: Images.VENDOR_ACTIVE,
  },
  {
    name: 'Order Management',
    path: ['/orders', '/order-details'],
    icon: Images.ORDERS,
    icon_active: Images.ORDERS_ACTIVE,
  },
  {
    name: 'Payment Management',
    path: ['/financials'],
    icon: Images.FINANCIALS,
    icon_active: Images.FINANCIALS_ACTIVE,
  },
  {
    name: 'Static Content',
    path: ['/static-content', '/add-faq'],
    icon: Images.STATIC_CONTENT,
    icon_active: Images.STATIC_CONTENT_ACTIVE,
  },
  {
    name: 'Notification ',
    path: ['/notifications', '/add-notifications'],
    icon: Images.NOTIFICATION,
    icon_active: Images.NOTIFICATION_ACTIVE,
  },
  {
    name: 'Banner Management',
    path: ['/banners', '/add-banner'],
    icon: Images.BANNER,
    icon_active: Images.BANNER_ACTIVE,
  },
  {
    name: 'Chats',
    path: ['/chats'],
    icon: Images.CHATS,
    icon_active: Images.CHATS_ACTIVE,
  },
  {
    name: 'Configuration Management',
    path: ['/clients'],
    icon: Images.CLIENTS,
    icon_active: Images.CLIENTS_ACTIVE,
  },
  {
    name: 'User Management',
    path: ['/users', '/user-profile'],
    icon: Images.USER,
    icon_active: Images.USER_ACTIVE,
  },
  {
    name: 'Product Library',
    path: ['/product-management', '/add-product', '/add-service'],
    icon: Images.PRODUCT_LIBRARY,
    icon_active: Images.PRODUCT_LIBRARY_ACTIVE,
  },

  {
    name: 'Production Management',
    path: [
      '/productions',
      '/create-production',
      '/production-detail',
      '/episode-detail',
      '/set-detail',
      '/location-detail',
      '/department-detail',
    ],
    icon: Images.PRODUCTION,
    icon_active: Images.PRODUCTION_ACTIVE,
  },
  // {
  //   path: ['/productions', '/create-production'],
  //   icon: Images.ROLES,
  //   icon_active: Images.ROLES_ACTIVE,
  // },
  {
    name: 'Studio Management',
    path: ['/company-management', '/create-company'],
    icon: Images.VIDEO_IC,
    icon_active: Images.VIDEO_IC_ACTIVE,
  },
  {
    name: 'Roles & Permissions',
    path: ['/roles', '/role-details'],
    icon: Images.ROLES,
    icon_active: Images.ROLES_ACTIVE,
  },
  {
    name: 'Request Management',
    path: ['/requests', '/view-request'],
    icon: Images.REQUEST,
    icon_active: Images.REQUEST_ACTIVE,
  },
  {
    name: 'Commission Management',
    path: ['/commissions', 'edit-commission'],
    icon: Images.COMISSIONS,
    icon_active: Images.COMISSIONS_ACTIVE,
  },
]
export const ADDRESS_TYPES = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
}
export const DELIVERY_MODES: any = {
  'Hand it to me': 'inHand',
  'Leave it at Location': 'leftAtDoor',
  // 'Leave it at Lcation': 'leaveItAtLocation',
}
export const PRODUCTION_TYPE = {
  FEATURE_FILM: 'Feature Film',
  TV_SERIES: 'TV Series',
  // COMMERCIAL: 'Commercial',
  // DOCUMENTARY: 'Documentary',
  // MUSIC_VIDEO: 'Music Video',
  // EVENT: 'Event',
  // OTHER: 'Other',
}
export const businessType = [
  'State-Owned Business',
  'Publicly-Listed Business',
  'Privately-Owned Business',
  'Charity',
  'Other',
]
export const OrderStatusLabel: any = {
  CANCELLED: 'Canceled',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
}
export enum ORDER_PRODUCT_TYPES {
  rent = 'RENT',
  buy = 'BUY',
  service = 'SERVICE',
}
export const ORDER_STATUS = [
  {
    label: 'Completed',
    value: 'COMPLETED',
  },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Canceled', value: 'CANCELED' },
]

export const PRODUCT_TYPE = [
  {
    label: 'Purchase',
    value: 'Purchase',
  },
  { label: 'Rental', value: 'Rental' },
  { label: 'Package', value: 'Package' },
  { label: 'Service', value: 'Service' },
]

// {
//   name: 'Vendor Mgmt.',
//   path: ['/vendors'],
//   icon: Images.ORDERS,
//   icon_active: Images.ORDERS_ACTIVE,
// },
// {
//   name: 'Order Mgmt',
//   path: ['/orders'],
//   icon: Images.PRODUCTS,
//   icon_active: Images.PRODUCTS_ACTIVE,
// },
// {
//   name: 'Payment Mgmt.',
//   path: ['/payment'],
//   icon: Images.BRANCH,
//   icon_active: Images.BRANCH_ACTIVE,
// },

// {
//   name: 'Static Content Mgmt.',
//   path: ['/static_content'],
//   icon: Images.CHATS,
//   icon_active: Images.CHATS_ACTIVE,
// },
// {
//   name: 'Notification Mgmt.',
//   path: ['/notifications'],
//   icon: Images.COMPANY,
//   icon_active: Images.COMPANY_ACTIVE,
// },
// {
//   name: 'Banner Mgmt.',
//   path: ['/banners'],
//   icon: Images.CLIENTS,
//   icon_active: Images.CLIENTS_ACTIVE,
// },
// {
//   name: 'Client Mgmt.',
//   path: ['/client_management'],
//   icon: Images.CLIENTS,
//   icon_active: Images.CLIENTS_ACTIVE,
// },

export const max20CharsRegex = /^.{0,20}$/

export const charAndNumbers = /^[a-zA-Z0-9]*$/ // Regex allows only alphabets and numbers
