import { Typography } from "@mui/material";

interface ToastNotification {
    title: string,
    text: string,
    onClick?: () => void;
};
export const ToastNotification = ({ title, text, onClick }: ToastNotification) => {
    return (
        <div onClick={() => { onClick && onClick() }}>
            <Typography variant="body1">
                {title}
            </Typography>
            <Typography variant="subtitle1">
                {text}
            </Typography>
        </div>
    )
}
