import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import endPoints from '../api/endpoint'
import store, { RootState } from './store'
import {
  deleteApiCall,
  getApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods'
import { notify } from '../Utils/toastify'
import { CommissionState } from '../types'
import { setCommonDataLoader } from './commonSlice'

const initialState: CommissionState = {
  commission: [],
  loading: false,
  error: false,
  vendorsLists: {},
  type: 0,
}
const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    setCommissionList: (state, action) => {
      const { payload }: any = action
      state.commission = payload
    },
    setVendorList: (state, action) => {
      const { payload }: any = action
      state.vendorsLists = payload
    },
    setType: (state: CommissionState, action: PayloadAction<number>) => {
      state.type = action.payload
    },
  },
})

export const { setCommissionList, setVendorList, setType } =
  commissionSlice.actions

export default commissionSlice.reducer

export const getandSetCommissionData = (cb?: any) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))
  getApiCall(
    endPoints.commissionManagement.commissionManagementList,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        dispatch(setCommissionList(data.data))
        cb(data.data)
      }
      dispatch(setCommonDataLoader(false))
    },

    (e: any) => {}
  )
}
export const addCommission = (values: any, cb: any) => {
  return (dispatch: any, getState: any) => {
    console.log('dataToSend', values)
    dispatch(setCommonDataLoader(true))
    postApiCall(
      endPoints.commissionManagement.addCommissionManagement,
      values,
      (s: any) => {
        const {
          data: { statusCode, message },
        } = s

        console.log('RESSS', s)
        if (statusCode && statusCode === 200) {
          notify(message, 'success')
          cb()
        }
      },
      (e: any) => {
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error')
        } else {
          notify(null, 'error')
        }
        dispatch(setCommonDataLoader(false))
      }
    )
  }
}

export const getVendorList = () => {
  const { dispatch } = store

  getApiCall(
    endPoints.commissionManagement.vendorList,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        dispatch(setVendorList(data.data))
      }
    },
    (e: any) => {}
  )
}
export const deleteVendor = (id: number, callback: Function) => {
  const body = {
    commisionId: id,
  }

  return (dispatch: any, getState: any) => {
    deleteApiCall(
      `${endPoints.commissionManagement.deleteCommision}/${id}`,
      body,
      (s: any) => {
        const {
          data: { statusCode },
        } = s

        if (statusCode && statusCode === 200) {
          callback()
        }
      },
      (e: any) => {
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error')
        } else {
          notify(null, 'error')
        }
      }
    )
  }
}
