import { createSlice } from '@reduxjs/toolkit'

interface PRODUCTS {
  companyList: any
  companyDetails: any
  productions: any
  countryList: any
  loading: boolean
  error: boolean
}

const initialState: PRODUCTS = {
  companyList: {},
  productions: {},
  companyDetails: {},
  countryList: [],
  loading: false,
  error: false,
}
const CompanyManagement: any = createSlice({
  name: 'Company management',
  initialState,
  reducers: {
    updateCompanyManagement: (state: any, action: any) => {
      return { ...state, ...action.payload }
    },
    resetCompanyManagement: (state, action: any) => {
      return initialState
    },
  },
})

export const {
  updateCompanyManagement,
  resetCompanyManagement,
} = CompanyManagement.actions

export default CompanyManagement.reducer
