import {
  configureStore,
  Action,
  combineReducers,
  Reducer,
  AnyAction,
  createSelector,
} from '@reduxjs/toolkit'
import auth from './authSlice'
import common from './commonSlice'
import thunk, { ThunkAction, ThunkMiddleware } from 'redux-thunk'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import logger from 'redux-logger'
import storage from 'redux-persist/lib/storage'
import { resetAuthorizationToken } from '../api'
import { rootReducer } from './rootReducer'

// const rootReducer = combineReducers({
//   auth,
//   common,
// })

const reducerProxy: Reducer = (state: RootState, action: AnyAction) => {
  // on logout reset redux state
  if (action.type === 'auth/logout') {
    localStorage.removeItem('token')
    resetAuthorizationToken()
    return rootReducer({} as RootState, action)
  }
  return rootReducer(state, action)
}

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  blacklist: [],
  // add reducer name to persist
  whitelist: ['auth', 'common'],
}
const persistedReducer = persistReducer(persistConfig, reducerProxy)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'development'
      ? getDefaultMiddleware({ serializableCheck: false, }).concat(thunk as ThunkMiddleware, logger)
      : getDefaultMiddleware({ serializableCheck: false, }).concat(thunk as ThunkMiddleware),
})
export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
const stateSelector = (state: RootState) => state;

export const currentActiveChat = createSelector(
  stateSelector,
  (state: RootState) => {
    return state.chat.activeChatId && state.chat.chatMap[state.chat.activeChatId]
      ? state.chat.chatMap[state.chat.activeChatId]
      : {};
  }
);


export default store
