import { createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'

interface PRODUCTS {
  products: any
  services: any
  tabValue:number
  loading: boolean
  error: boolean
}

const initialState: PRODUCTS = {
  products: {},
  services: {},
  tabValue:0 ,
  loading: true,
  error: false,
}
const ProductManagement: any = createSlice({
  name: 'product management',
  initialState,
  reducers: {
    updateProductManagement: (state: any, action: any) => {
      return { ...state, ...action.payload }
    },
    resetProductManagement: (state, action: any) => {
      return initialState
    },
  },
})

export const {
  updateProductManagement,
  resetProductManagement,
} = ProductManagement.actions

export default ProductManagement.reducer
