import { Avatar, Button, Menu, MenuItem } from '@mui/material'
import Images from '../Utils/images'
import React, { useEffect, useMemo } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { RootState, useAppSelector } from '../redux/store'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/authSlice'
import { resetAuthorizationToken } from '../api'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../helpers/contants'
import InAppNotification from '../Components/InAppNotification';

export default function Header(): JSX.Element {
  const dispatch = useDispatch()
  const MemomisePopUp = useMemo(() => <InAppNotification />, []);
  const { userData } = useAppSelector((state: RootState) => state.auth)
  const userDetails = useAppSelector(
    (state: RootState) => state.profile.userData
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  useEffect(() => {
    if (userDetails) {
      localStorage.setItem('userDetails', JSON.stringify(userDetails));
    }
  }, [userDetails]);
  
  const handleClose = (action: string) => {
    if (action === 'logout') {
      dispatch(logout())
      resetAuthorizationToken()
    }
    setAnchorEl(null)
  }

  const navigate = useNavigate()
  return (
    <header className="header">
      <div className="logo">
        <img src={Images.LOGO} alt="Logo" />
      </div>
      <div className="authAction">
      {/* {(location.pathname !== ROUTES.BUSINESSINFO ||
              location.pathname !== ROUTES.WELCOME) &&
              MemomisePopUp} */}
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div className="userDropdown">
            <Avatar
              alt="User name"
              src={
                // userDetails?.profilePicture
                //   ? userDetails?.profilePicture
                //   : Images.AUTH_BG_1
                userDetails?.profilePicture
                  ? userDetails.profilePicture
                  : userData?.profilePicture
                  ? userData.profilePicture
                  : Images.AUTH_BG_1
              }
            />
            {/* IN CASE OF NO IMAGE AVAULABLE */}
            {/* <Avatar>H</Avatar> */}
            <div className="info">
              <h4>
                {userDetails.firstname
                  ? userDetails.firstname
                  : userData.firstname}{' '}
                {userDetails.lastname
                  ? userDetails.lastname
                  : userData.lastname}
              </h4>
              <p>{userDetails.email ? userDetails.email : userData.email}</p>
            </div>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'user-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(ROUTES.EDIT_PROFILE)
            }}
          >
            Profile
          </MenuItem>
          {/* <MenuItem onClick={() => handleClose('')}>My account</MenuItem> */}
          <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
        </Menu>
      </div>
    </header>
  )
}
