import React, { useEffect } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import '../../styles/auth.scss'
import Business from './Components/Business'
import ContactInfo from './Components/ContactInfo'
import LegalDocuments from './Components/LegalDocuments'
import { styled } from '@mui/material/styles'
import { StepIconProps } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import Success from '../../Components/Success'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppSelector } from '../../redux/store'
import { getAndSetData } from '../../redux/commonSlice'
import { ROUTES } from '../../helpers/contants'
function BusinessInfo() {
  const { userData } = useAppSelector((state: RootState) => state.auth)
  const { countryList, departmentList } = useAppSelector(
    (state: RootState) => state.common
  )

  const [activeStep, setActiveStep] = React.useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (userData) {
      if (userData.formNextStep === 'CONTACT_INFO') {
        setActiveStep(1)
      } else if (userData.formNextStep === 'LEGAL_DOC') {
        setActiveStep(2)
      } else if (userData.formNextStep === 'BILLING_DETAILS') {
        navigate(ROUTES.DASHBOARD)
      }
      if (countryList.length === 0) {
        getAndSetData('country')
      }
      if (departmentList && departmentList.length === 0) {
        getAndSetData('department')
      }
    }
  }, [userData])

  const steps = [
    'Business information',
    'Contact Information',
    'Legal document',
    // 'Billing Details',
  ]

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
  }>(({ theme, ownerState }) => ({
    backgroundColor: '#ffffff',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #9DA4AE',
    ...(ownerState.active && {
      backgroundColor: '#EEF3F7',
      border: '2px solid #5089AC',
    }),
    ...(ownerState.completed && {
      backgroundColor: '#5089AC',
      border: '2px solid #5089AC',
    }),
  }))

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {completed ? (
          <CheckIcon />
        ) : (
          // <CircleOutlinedIcon />
          <></>
        )}
      </ColorlibStepIconRoot>
    )
  }

  const switchForms = (index: number) => {
    if (userData) {
      const { formNextStep } = userData

      if (
        formNextStep === 'BUSINESS_INFO' ||
        formNextStep === 'BILLING_DETAILS'
      ) {
        return
      } else if (formNextStep === 'CONTACT_INFO' && index <= 1) {
        setActiveStep(index)
      } else if (formNextStep === 'LEGAL_DOC' && index <= 2) {
        setActiveStep(index)
      }
    }
  }

  return (
    <div className="businessInfo">
      <div className="infoHeader">
        <h1>Vendor Registration</h1>
        <div className="stepper">
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: {
                optional?: React.ReactNode
              } = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    StepIconComponent={ColorlibStepIcon}
                    onClick={() => switchForms(index)}
                  >
                    {label}
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </div>
      </div>
      <div className="stepperContainer">
        {activeStep === 0 && <Business changeStep={setActiveStep} />}
        {activeStep === 1 && <ContactInfo changeStep={setActiveStep} />}
        {activeStep === 2 && <LegalDocuments changeStep={setActiveStep} />}
        {activeStep === 3 && (
          <Success
            heading="Thanks for Registering!"
            title="Your registration has been successfully completed."
            buttonText="Go to Dashboard"
            handleClick={() => navigate('/')}
          />
        )}
      </div>
    </div>
  )
}

export default BusinessInfo
