import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    ChatPage,
    ChatState,
    Employee,
    MediaDetails,
    MessageType,
    Quotation,
    SendMessageType,
} from './chat.types'
import { Chat } from './chat.types'
import { AppDispatch } from '../../redux/store'
import { commonUploadFn } from '../../Utils/AwsService'
import { generateRandomString } from '../../Utils/helperFns'
import { CHAT_FAILED, CHAT_ORDER_COMPLETED, CHAT_UPLOADING, MEDIA_TYPES } from '../../helpers/contants'
import {
    EmitEventFunction,
    PAGE_LIMIT,
    SocketEvents,
} from './SocketContext'
import dayjs from 'dayjs'
import { getApiCall } from '../../api/methods'
import endPoints from '../../api/endpoint'

export type MediaWithFile = MediaDetails & {
    file: File
}
type Progress = Record<string, number>

let globalProgress: Record<string, Progress> = {};
let globalAbort: Record<string, Record<string, boolean>> = {};

const defaultPage: ChatPage = {
    nextPage: 0,
    hasMore: false,
    limit: PAGE_LIMIT,
    loading: false,
};
const initialChatState: ChatState = {
    chats: [],
    chatMap: {},
    activeChatId: '',
    currentActiveUserId: '',
    fileProgress: {},
    loadingChats: true,
    selectedQuote: null,
    messages: {
        messagesMap: {},
        lastRead: {},
        loading: {},
        page: {},
    },
    users: {
        isLoading: false,
        usersMap: {},
    },
    orderToCreate: null,
    selectedIds: [],
    productsAndServiceList: [],
    editedOrderQoutationId: null,
    quoteOrderMap: {},
    lastActiveChat: '',
    employeeList: [],
}
const chatSlice = createSlice({
    name: 'chatSlice',
    initialState: initialChatState,
    reducers: {
        addChats: (state, action: PayloadAction<any[]>) => {
            const { payload } = action;
            const chatToSave = state.chats.filter((oldchat: Chat) => oldchat.isNewChat);
            const newChats = [...payload, ...chatToSave];
            const newChatMap = newChats
                .sort((a: Chat, b: Chat) => {
                    const dateA = dayjs(a.lastMsgCreated)
                    const dateB = dayjs(b.lastMsgCreated)
                    if (dateA.isBefore(dateB)) {
                        return 1
                    } else if (dateA.isAfter(dateB)) {
                        return -1
                    } else {
                        return 0
                    }
                })
                .reduce((map, item: Chat) => {
                    if (item._id) {
                        map[`${item._id}`] = item
                    }
                    return map
                }, {});
            state.chats = newChats
            state.chatMap = newChatMap
            state.loadingChats = false
            return state
        },
        resetChat: (state, _: PayloadAction<any>) => {
            state = initialChatState;
            return state;
        },
        setActiveChat: (state, action: PayloadAction<string>) => {
            const { payload } = action
            state.activeChatId = payload
            const skipUpdateChatMap = payload === '';
            const activeChat: Chat =
                payload && state.chatMap[payload]
                    ? {
                        ...state.chatMap[payload],
                        noOfUnreadMessage: 0,
                    }
                    : {}
            state.currentActiveUserId = activeChat?.contactDetails?._id || ''
            if (skipUpdateChatMap) {
                return state;
            }
            state.chatMap[payload] = payload ? activeChat : {}
            return state;
        },
        selectQuote: (state, action: PayloadAction<Quotation | null>) => {
            const { payload } = action
            state.selectedQuote = payload
            return state
        },
        setOrderMessage: (state, action: PayloadAction<SendMessageType | null>) => {
            const { payload } = action
            state.orderToCreate = payload
            return state
        },
        setLoadingMessage: (state, action: PayloadAction<{ chatId: string, isLoading: boolean }>) => {
            const { payload: { chatId, isLoading } } = action;
            state.messages.loading = {
                ...state.messages.loading,
                [chatId]: isLoading
            }
            return state
        },
        setLastActiveChat: (state, action: PayloadAction<string>) => {
            state.lastActiveChat = action.payload;
            return state
        },
        setUserStatus: (
            state,
            action: PayloadAction<{ contactId?: string; status: Record<string, any> }>
        ) => {
            const { payload } = action
            const useableContactId = payload.contactId || state?.currentActiveUserId
            if (!useableContactId) {
                return
            }
            state.users.isLoading = false
            state.users.usersMap = {
                ...state.users.usersMap,
                [`${useableContactId}`]: payload.status,
            }
            return state
        },
        checkUserAndUpdateStatus: (
            state,
            action: PayloadAction<{ contactId?: string; status: Record<string, any> }>
        ) => {
            const { payload } = action
            const currentChats = state.chats
            if (
                currentChats.some((chat: Chat) =>
                    chat.members?.includes(`${payload.contactId}`)
                )
            ) {
                state.users.isLoading = false
                state.users.usersMap = {
                    ...state.users.usersMap,
                    [`${payload.contactId}`]: payload.status,
                }
                return state
            }
        },
        setFileProgress: (
            state,
            action: PayloadAction<{ totalProgress: Progress; chatId: string }>
        ) => {
            const {
                payload: { totalProgress, chatId },
            } = action
            state.fileProgress[chatId] = totalProgress
            return state
        },
        setNewMessages: (state, action: PayloadAction<any>) => {
            const { payload } = action;
            // Check here if the chat has new message or it has the old one
            const chatId = payload.chatId;
            let chatPageNo: ChatPage = JSON.parse(JSON.stringify(state.messages.page[chatId] || {}));
            chatPageNo = {
                ...(Object.keys(chatPageNo).length > 0 ? chatPageNo : defaultPage),
                // if new messages length = no. of pages being fetched
                hasMore: payload.messages.length === PAGE_LIMIT,
                loading: false,
            }
            chatPageNo.nextPage = chatPageNo.nextPage + 1;
            const currentMessages = JSON.parse(JSON.stringify(state.messages.messagesMap[chatId] || []));
            state.messages.messagesMap = {
                ...state.messages.messagesMap,
                [`${chatId}`]: [...currentMessages, ...payload.messages],
            }
            state.messages.page = {
                ...state.messages.page,
                [`${chatId}`]: chatPageNo,
            }
            state.messages.loading = {
                ...state.messages.loading,
                [`${chatId}`]: false,
            }
            // console.log(chatId, chatPageNo, currentMessages, payload.messages);
            return state
        },
        receiveMessage: (state, action: PayloadAction<{ chat: Chat }>) => {
            const { payload } = action
            const chatId = payload.chat._id
            if (chatId) {
                delete state.chatMap[chatId]
                const newChatMap = JSON.parse(
                    JSON.stringify({ [chatId]: payload.chat, ...state.chatMap })
                )
                state.chatMap = newChatMap
                state.chats = Object.values(newChatMap);
            }
            return state
        },
        lastReadMessage: (
            state,
            action: PayloadAction<{ lastRead: Date | number; chatId: string }>
        ) => {
            const {
                payload: { lastRead, chatId },
            } = action
            state.messages.lastRead = {
                ...state.messages.lastRead,
                [chatId]: lastRead,
            }
            return state
        },
        orderCheckedOut: (
            state,
            action: PayloadAction<{ messageId: string; quotationId: string }>
        ) => {
            const {
                payload: { messageId, quotationId },
            } = action;
            if (!quotationId || !messageId) {
                console.log('req ID not present message, quote ids');
                return;
            }
            state.quoteOrderMap = {
                ...state.quoteOrderMap,
                [quotationId]: CHAT_ORDER_COMPLETED,
            }
            return state
        },
        // has logic if recevie message gave same response as send message
        addMessage: (
            state,
            action: PayloadAction<{
                chatId?: string
                message: MessageType
                chat?: Chat
                isRecevieMessage?: boolean
                currentUserId?: string
                handleEmitEvent?: EmitEventFunction
                callback?: () => void;
            }>
        ) => {
            const { payload } = action
            const chatIdFromMessage = payload.chatId || payload.message?.chatId
            if (!chatIdFromMessage) {
                // console.log('no-chat');
                return
            }
            if (
                payload.isRecevieMessage &&
                state.activeChatId === chatIdFromMessage
            ) {
                return
            }
            // get current messages
            let currentMessages: MessageType[] = JSON.parse(
                JSON.stringify(state.messages.messagesMap[`${chatIdFromMessage}`] || [])
            )
            // handle for new vendor, if it's new vendor with nothing in chatmap add it in the map
            const currentChatDetails: any = state.chatMap[chatIdFromMessage]
                ? state.chatMap[chatIdFromMessage]
                : {
                    ...(payload.chat ? payload.chat : payload.message),
                }
            currentChatDetails.lastMessageDetail = {
                ...payload.message,
            }
            // handle np. of unread messages
            const messageToAdd = payload.currentUserId ? payload.currentUserId === payload.message.senderId ? 0 : 1 : 1;
            currentChatDetails.noOfUnreadMessage =
                (currentChatDetails?.noOfUnreadMessage || 0) +
                (state.activeChatId === chatIdFromMessage ? 0 : messageToAdd)
            // delete old chat from map
            delete state.chatMap[chatIdFromMessage]
            // add the new chat to map
            const newChatMap: Record<string, Chat> = {};
            const chatsParsed = JSON.parse(
                JSON.stringify({
                    [chatIdFromMessage]: currentChatDetails,
                    ...state.chatMap,
                })
            );
            Object.keys(chatsParsed).forEach((key: string) => {
                if (key) {
                    newChatMap[key] = chatsParsed[key];
                }
            })
            state.chatMap = newChatMap;
            state.chats = Object.values(newChatMap);
            // if currently there are no messages fetch the listing
            if (!currentMessages.length && payload.handleEmitEvent) {
                payload.handleEmitEvent('messageListing', {
                    chatId: chatIdFromMessage,
                    limit: PAGE_LIMIT,
                })
                return state;
            }
            let replacedMessage = false
            // if we have a media so replace with dummy message
            if (
                MEDIA_TYPES.includes(payload.message.messageType) &&
                payload.message.intermediateId
            ) {
                for (let i = 0; i < currentMessages.length / 2; i++) {
                    if (
                        currentMessages[i]._id === payload.message.intermediateId &&
                        payload.message.mediaDetail?.length ===
                        currentMessages[i].mediaDetail?.length
                    ) {
                        currentMessages[i] = payload.message
                        replacedMessage = true
                        break
                    }
                }
            }
            // this is for latest order created to disable old ORDERS
            if (payload.message.messageType === 'CART') {
                state.quoteOrderMap = {
                    ...state.quoteOrderMap,
                    [`${payload.message.cartDetail?.quotationId}`]: `${payload.message._id}`,
                }
            }
            state.messages.messagesMap = {
                ...state.messages.messagesMap,
                [`${chatIdFromMessage}`]: replacedMessage
                    ? currentMessages
                    : [{ ...payload.message }, ...currentMessages],
            }
            if (payload.callback) {
                payload.callback();
            }
            return state
        },
        updateMessage: (
            state,
            action: PayloadAction<{
                chatId: string
                messageId?: string
                updatedMessage?: MessageType
            }>
        ) => {
            const { chatId, messageId, updatedMessage } = action.payload
            const currentMessages: MessageType[] = JSON.parse(
                JSON.stringify(state.messages.messagesMap[`${chatId}`] || [])
            )
            const updatedMap = currentMessages.map((value) => {
                if (value._id === messageId) {
                    return updatedMessage
                } else {
                    return value
                }
            })
            state.messages.messagesMap = {
                ...state.messages.messagesMap,
                [`${chatId}`]: updatedMap,
            }
        },

        ProductsAndServiceIds: (state, action) => {
            const { payload }: any = action
            state.selectedIds = payload
        },

        AddProductsAndServiceList: (state, action) => {
            const { payload }: any = action
            state.productsAndServiceList = payload
        },
        setEditedOrderQoutationId: (state, action) => {
            const { payload }: any = action
            state.editedOrderQoutationId = payload
        },
        startChatLoad: (state, action: PayloadAction<{ chatId: string }>) => {
            const { chatId } = action.payload
            state.messages.page = {
                ...state.messages.page,
                [chatId]: {
                    ...state.messages.page[chatId],
                    loading: true,
                }
            }
        },
        setEmployeeList: (state, action: PayloadAction<Employee[]>) => {
            state.employeeList = action.payload;
            return state;
        },
        setLoadingChats: (state, action: PayloadAction<boolean | undefined>) => {
            state.loadingChats = !!action.payload;
            return state;
        },
        setUnreadZero: (state, action: PayloadAction<string>) => {
            if (!action.payload) {
                return;
            }
            state.chatMap[action.payload] = {
                ...state.chatMap[action.payload],
                noOfUnreadMessage: 0,
            }
            return state;
        },
    },
})
export const {
    setUnreadZero,
    setLoadingChats,
    addChats,
    setNewMessages,
    setActiveChat,
    addMessage,
    setUserStatus,
    checkUserAndUpdateStatus,
    receiveMessage,
    selectQuote,
    setFileProgress,
    updateMessage,
    lastReadMessage,
    ProductsAndServiceIds,
    AddProductsAndServiceList,
    setOrderMessage,
    setEditedOrderQoutationId,
    orderCheckedOut,
    setLoadingMessage,
    startChatLoad,
    setLastActiveChat,
    setEmployeeList,
    resetChat,
} = chatSlice.actions

export const chatReducer = chatSlice.reducer

// Taking a lot of params here because we need to run this function stand alone once called
export const uploadFile =
    (payload: {
        media: Record<string, MediaWithFile>
        defaultProgress?: Progress
        handleProgress: (value: Progress) => void
        chatId: string
        messageToSend: Record<string, any>
        currentUserId: string,
        socketEmitEvent: (type: SocketEvents, params?: any) => void
    }) =>
        (dispatch: AppDispatch) => {
            const {
                defaultProgress,
                media,
                handleProgress,
                chatId,
                messageToSend,
                socketEmitEvent,
                currentUserId,
            } = payload
            let progress = { ...defaultProgress }
            let currentMedia = {
                ...media,
            }
            // const {
            //   auth: { userData },
            // }: RootState = store.getState()
            // const currentUserId = userData?.branchId;
            // call add message with uploading here
            const noOfMedia = Object.values(media).length
            const message: MessageType = {
                _id: generateRandomString(10),
                messageType: messageToSend.messageType,
                mediaDetail: [],
                senderId: currentUserId,
                isRead: [],
                isDelivered: [],
                status: CHAT_UPLOADING,
                message: '',
                createdAt: new Date(),
                created: new Date().getTime() * 1000,
                chatId,
            }
            if (noOfMedia > 0) {
                const dummyMediaDetails: MediaDetails[] = []
                Object.values(media).forEach(
                    (mediaItem: MediaWithFile, index: number) => {
                        const { file, ...rest } = mediaItem
                        const dummyFileDetails: MediaDetails = {
                            ...rest,
                            mediaLocalUrl: file.webkitRelativePath,
                        }
                        dummyMediaDetails.push(dummyFileDetails)
                        // emit a messgae and wait for response
                        const initalMessage = {
                            ...messageToSend,
                            status: CHAT_UPLOADING,
                            mediaDetail: [dummyFileDetails],
                        }
                        // send inital message of upload
                        if (index === noOfMedia - 1) {
                            message.mediaDetail = dummyMediaDetails
                            dispatch(addMessage({ chatId, message, currentUserId }))
                        }
                        const uploadObject = commonUploadFn(
                            file,
                            (fileProgress: number) => {
                                // call abort or check abort here
                                const newProgress = {
                                    ...progress,
                                    [`${mediaItem.mediaName}-${mediaItem.mediaSize}`]: fileProgress,
                                }
                                progress = newProgress
                                dispatch(
                                    setFileProgress({
                                        totalProgress: progress,
                                        chatId,
                                    })
                                )
                                // if user leaves then still keep the track of progress
                                globalProgress = {
                                    ...globalProgress,
                                    [chatId]: progress,
                                }
                                globalAbort = {
                                    ...globalAbort,
                                    [chatId]: {
                                        ...globalAbort[chatId],
                                        [`${mediaItem.mediaName}-${mediaItem.mediaSize}`]: true,
                                    },
                                }
                                handleProgress(progress)
                            },
                            (url: string | null) => {
                                if (url) {
                                    const newDetails = {
                                        ...currentMedia,
                                        [mediaItem.mediaName]: {
                                            ...currentMedia[mediaItem.mediaName],
                                            mediaUrl: url,
                                        },
                                    }
                                    // const messageIdFromBE = globalAbort[chatId][`${mediaItem.mediaName}-${mediaItem.mediaSize}`];
                                    // send emit event for successfull send here
                                    currentMedia = newDetails
                                    if (
                                        Object.values(currentMedia).every(
                                            (item: MediaWithFile) => item.mediaUrl !== ''
                                        )
                                    ) {
                                        const uploadedMedia: MediaDetails[] = []
                                        const failedMedia: MediaDetails[] = []
                                        Object.values(currentMedia).forEach((item: MediaWithFile) => {
                                            const { file, ...rest } = item
                                            if (!item.failed) {
                                                uploadedMedia.push(rest)
                                            } else {
                                                failedMedia.push(rest)
                                            }
                                        })
                                        const messageObject = {
                                            ...messageToSend,
                                            mediaDetail: uploadedMedia,
                                            intermediateId: message._id,
                                        }
                                        socketEmitEvent('sendMessage', messageObject)
                                        // handle the failed media message update
                                        if (failedMedia.length > 0) {
                                            dispatch(
                                                updateMessage({
                                                    chatId: chatId,
                                                    messageId: message._id,
                                                    updatedMessage: {
                                                        ...message,
                                                        mediaDetail: failedMedia,
                                                        status: CHAT_FAILED,
                                                    },
                                                })
                                            )
                                        }
                                        // else {
                                        //     dispatch(updateMessage({
                                        //             chatId: chatId,
                                        //             messageId: message._id,
                                        //             updatedMessage: {
                                        //             ...message,
                                        //             mediaDetail: failedMedia,
                                        //             status: CHAT_DELETED,
                                        //         }
                                        //     }))
                                        // }
                                    }
                                } else {
                                    const newDetails = {
                                        ...currentMedia,
                                        [mediaItem.mediaName]: {
                                            ...currentMedia[mediaItem.mediaName],
                                            mediaUrl: 'failed',
                                            failed: true,
                                        },
                                    }
                                    currentMedia = newDetails
                                }
                            }
                        )
                    }
                )
            }
        }

export const updateEmployeeList = (payload: { id: string }) => (dispatch: AppDispatch) => {
    getApiCall(endPoints.chat.employeeList, ({ data }: any) => {
        const employee = data.data.employee as Employee[];
        dispatch(setEmployeeList(employee));
    }, (error: any) => {
        console.log(error)
    })
}