import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React from 'react'
import { Controller } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { FormControl, FormHelperText, FormLabel } from '@mui/material'
import Images from '../Utils/images'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'

interface Props {
  label?: string
  name: string
  formProps?: any
  minDate?: string
  disabled?: boolean
  error?: boolean
  helperText?: any
  requiredField?: boolean
  value: any
  onChange?: any
}

const DateInput: React.FC<Props> = (props) => {
  const {
    label,
    name,
    formProps,
    minDate = null,
    value,
    onChange,
    disabled,
    error,
    helperText,
    requiredField,
  } = props

  return formProps && formProps.control ? (
    <Controller
      control={formProps.control}
      name={name}
      render={({ field }) => (
        <FormControl
          fullWidth
          className={`customSelect ${error ? 'border-red' : ''}`}
          error={error ? true : false}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel className="formLabel">
              {label} {requiredField ? <sup>*</sup> : null}{' '}
            </FormLabel>
            <DatePicker
              className="dateInputField"
              {...field}
              disabled={disabled}
              // value={dayjs(field.value)}
              // defaultValue={dayjs(
              //   new Date(`${new Date().getFullYear() - 19}-01-01`).toISOString()
              // )}
              value={field.value ? dayjs(field.value) : null}
              views={['year', 'day']}
              showDaysOutsideCurrentMonth={false}
              minDate={dayjs(minDate)}
              maxDate={dayjs(
                new Date(`${new Date().getFullYear() - 19}-12-31`).toISOString()
              )}
              // maxDate={new Date('2004-12-31')}
              slots={{
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              disableFuture
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              sx={{
                width: '100%',
                input: {
                  padding: '8.5px 14px',
                },
              }}
            />
          </LocalizationProvider>
          <FormHelperText className={error ? 'Mui-error' : ''}>
            {helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  ) : (
    <FormControl
      fullWidth
      className={`customSelect ${error ? 'border-red' : ''}`}
      error={error ? true : false}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormLabel className="formLabel">{label}</FormLabel>
        <DatePicker
          disabled={disabled}
          // defaultValue={dayjs(new Date().toISOString())}
          // value={dayjs(value)}

          value={value ? dayjs(value) : null}
          onChange={(e) => onChange(e)}
          // label={label}
          views={['year', 'day']}
          showDaysOutsideCurrentMonth={false}
          minDate={dayjs(minDate)}
          disableFuture
          slots={{
            openPickerIcon: CalendarMonthOutlinedIcon,
          }}
          slotProps={{
            textField: {
              size: 'small',
              inputProps: {
                // not InputProps
                readOnly: true,
              },
            },
          }}
          sx={{
            width: '100%',
            input: {
              padding: '8.5px 14px',
            },
          }}
        />
      </LocalizationProvider>
      <FormHelperText className={error ? 'Mui-error' : ''}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default DateInput
