import { createSlice } from '@reduxjs/toolkit'
import { BannerSortKeys } from '../../types'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'

interface BANNERS {
  users: any
  vendors: any
  tabValue:number
  loading: boolean
  error: boolean
  sortKey: BannerSortKeys;
  sortMode: 1 | -1;
}

const initialState: BANNERS = {
  users: {},
  vendors: {},
  tabValue:0 ,
  loading: false,
  error: false,
  sortKey: BannerSortKeys.bannerTitle,
  sortMode: 1,
}
const BannmerManagement: any = createSlice({
  name: 'banner management',
  initialState,
  reducers: {
    updateBannerManagement: (state: any, action: any) => {
      return { ...state, ...action.payload }
    },
    resetBannerManagement: (state, action: any) => {
      return initialState
    },
    updateSortKey: (state, action) => {
      const { payload }: any = action;
      state.sortKey = payload;
    },
    updateSortMode: (state, action) => {
      const { payload }: any = action;
      state.sortMode = payload;
    },
  },
})

export const {
  updateBannerManagement,
  resetBannerManagement,
  updateSortKey,
  updateSortMode
} = BannmerManagement.actions

export default BannmerManagement.reducer
