import * as yup from 'yup'
import { ErrorMsg, MaxFileSizeLimit } from '../helpers/contants'
import { ADDRESS_TYPES, businessType } from './constantData'

export const regexForZipcode = /^[A-Za-z0-9\s]+$/

export const RegisterFormSchema = yup
  .object({
    firstName: yup
      .string()
      .required(ErrorMsg('First Name').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^[a-zA-Z]+$/, ErrorMsg('First Name').onlyLetter),
    lastName: yup
      .string()
      .required('Last Name is required.')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min)
      .matches(/^[a-zA-Z]+$/, 'Last Name should only contain letters'),
    email: yup
      .string()
      .required('Email is required.')
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/,
        'Please enter a valid email.'
      )
      .max(100, 'Email must be at most 100 characters long.')
      .email('Please enter valid email.'),
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters')
      .max(16, 'Password must be less then 17 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        'Password must include at least one uppercase letter, one lowercase letter, and one numeric digit'
      )
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
    termsAndConditions: yup
      .boolean()
      .oneOf([true], 'You must accept the Terms and Conditions'),
  })
  .required()
export type CancelFormInterface = yup.InferType<typeof CancelFormSchema>
export const CancelFormSchema = yup.object({
  cancelReason: yup.string().required(ErrorMsg('Reason').required),
  cancelNote: yup
    .string()
    .required('Notes is required for cancellation.')
    .min(3, ErrorMsg(3).min)
    .max(500, ErrorMsg(500).max),
})

export const numberValidation = (nullable: boolean = false) => {
  if (nullable) {
    return yup
      .number()
      .typeError('Enter a valid number ') // Display custom error message for non-numeric values
      .test(
        'is-decimal',
        'Enter a valid number with up to two decimal places',
        (value: any) => {
          if (!value) return true // Allow empty string or undefined

          // Check if the value is a valid number with up to two decimal places
          return /^\d+(\.\d{0,2})?$/.test(value)
        }
      )

      .min(0, 'Value must be 0 or positive')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .nullable()
  } else {
    return yup
      .number()
      .typeError('Enter a valid number') // Display custom error message for non-numeric values
      .test(
        'is-decimal',
        'Enter a valid number with up to two decimal places',
        (value: any) => {
          if (!value) return true // Allow empty string or undefined

          // Check if the value is a valid number with up to two decimal places
          return /^\d+(\.\d{0,2})?$/.test(value)
        }
      )
      .min(0, 'Value must be 0 or positive') // Optional: Ensure the number is positive
  }
}
export const AddAnotherDeliveryAddessSchema = yup.object({
  addressType: yup.string(),
  locationName: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Address').required),
  }),
  setName: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Set name').required),
  }),
  episodeName: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Episode name').required),
  }),
  apartment: yup
    .string()
    .required(ErrorMsg('Apatment/Suite Name').required)
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
  // .matches(/^[a-zA-Z]+$/, ErrorMsg('Set Name').onlyLetter),
  buildingName: yup
    .string()
    .required(ErrorMsg('Building Name').required)
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
  landmark: yup
    .string()
    .required(ErrorMsg('Landmark').required)
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
  deliveryStatus: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
    then: (schema) => schema.required(ErrorMsg('Drop Off').required),
  }),
  zipCode: yup
    .string()
    .when('addressType', {
      is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
      then: (schema) => schema.required(ErrorMsg('Zipcode').required),
    })
    .min(3, ErrorMsg(3).min)
    .max(10, ErrorMsg(10).max),
  address: yup
    .string()
    .when('addressType', {
      is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
      then: (schema) => schema.required(ErrorMsg('Location').required),
    })
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
})
export const nullableStringValidation = yup
  .string()
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value
  })
  .nullable()

export const AddProductFormSchema = yup
  .object({
    // department: yup.string().required('Please select department.'),
    department: yup
      .object()
      .required('Please select department.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0
      ),
    // department: yup
    //   .array()
    //   .min(1)
    //   .required('at least one item needs to be here'),
    // category: yup.array().min(1).required('at least one item needs to be here'),
    category: yup
      .object()
      .required('Please select Category.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0
      ),
    type: yup.string().required('Please select Type.'),
    title: yup
      .string()
      .required(ErrorMsg('Title').required)
      .min(3, ErrorMsg(3).min)
      .max(60, ErrorMsg(60).max),
    productModel: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(60, ErrorMsg(60).min),
    sku: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),

    description: yup.string().max(5000, ErrorMsg(5000).max),

    files: yup
      .mixed()
      // .test(
      //   'fileSize',
      //   'Total files should be less than 35MB',
      //   (value: any) => {
      //     if (!value) return true;
      //     const totalSize: any = Array.from(value).reduce(
      //       (acc, file: any) => acc + file.size,
      //       0
      //     );
      //     return totalSize <= 36700160; // 35MB in bytes
      //   }
      // )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        ).length

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4'
        ).length

        return imageCount + videoCount === Array.from(value).length
      })
      .test(
        'imageSize',
        'Each image should be less than 10MB',
        (value: any) => {
          if (!value) return true
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('video/')
                ? true
                : file.type.startsWith('image/') && file.size <= 10485760 // 10MB in bytes
          )
        }
      )
      .test(
        'videoSize',
        'Each video should be less than 50MB',
        (value: any) => {
          if (!value) return true
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('image/')
                ? true
                : file.type.startsWith('video/') && file.size <= 52428800 // 50MB in bytes 48060415
          )
        }
      ),

    // files: yup
    //   .mixed()
    //   .test(
    //     'fileSize',
    //     'Total files should be less than 35MB',
    //     (value: any) => {
    //       if (!value) return true
    //       const totalSize: any = Array.from(value).reduce(
    //         (acc, file: any) => acc + file.size,
    //         0
    //       )
    //       return totalSize <= 36700160
    //     }
    //   )
    //   .test('fileType', 'Invalid file type', (value: any) => {
    //     if (!value) return true

    //     const imageCount = Array.from(value).filter(
    //       (file: any) =>
    //         file.type === 'image/jpeg' ||
    //         file.type === 'image/jpg' ||
    //         file.type === 'image/png'
    //     ).length

    //     const videoCount = Array.from(value).filter(
    //       (file: any) => file.type === 'video/mp4'
    //     ).length

    //     return imageCount + videoCount === Array.from(value).length
    //   })
    //   .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
    //     if (!value) return true
    //     return Array.from(value).every((file: any) =>
    //       file.type.startsWith('video/')
    //         ? true
    //         : file.type.startsWith('image/') && file.size <= 3145728
    //     ) // 3MB in bytes
    //   })
    //   .test('videoSize', 'Video should be less than 30MB', (value: any) => {
    //     if (!value) return true
    //     return Array.from(value).every((file: any) =>
    //       file.type.startsWith('image/')
    //         ? true
    //         : file.type.startsWith('video/') && file.size <= 31457280
    //     )
    //   }),

    attributes: yup
      .array()
      .of(
        yup.object().shape({
          key: yup
            .string()
            .required(ErrorMsg('Attribute name').required)
            .max(30, ErrorMsg(30).max),
          value: yup
            .string()
            .required(ErrorMsg('Description').required)
            .max(30, ErrorMsg(30).max),
        })
      )
      .test(
        'atLeastOneValue',
        'At least one value is required',
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0
          }
          // Handle other types if needed
          return false
        }
      ),
  })
  .required()

const TagsSchema = yup.object({
  tags: yup
    .array()
    .of(
      yup.object().shape({
        tag: yup
          .string()
          .required('String is required')
          .max(20, 'String must have at most 20 characters'),
      })
    )
    .min(1, 'At least one tag is required')
    .max(3, 'Maximum of three tags allowed'),
  cardTags: yup
    .array()
    .of(
      yup.object().shape({
        tag: yup
          .string()
          .required('String is required')
          .max(20, 'String must have at most 20 characters'),
      })
    )
    .min(1, 'At least one tag is required')
    .max(3, 'Maximum of three tags allowed'),
})

export const AddServiceFormSchema = yup
  .object({
    // department: yup
    //   .array()
    //   .min(1)
    //   .required('at least one item needs to be here'),
    // category: yup.array().min(1).required('at least one item needs to be here'),
    // type: yup.string().default('Service'),
    department: yup
      .object()
      .required('Please select department.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0
      ),

    category: yup
      .object()
      .required('Please select Category.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0
      ),
    title: yup
      .string()
      .required(ErrorMsg('Title').required)
      .min(3, ErrorMsg(3).min)
      .max(60, ErrorMsg(60).max),
    // subTitle: yup.string().min(3, ErrorMsg(3).min).max(60, ErrorMsg(60).max),
    // isFeatureProduct: yup.boolean().default(false),
    description: yup.string().max(5000, ErrorMsg(5000).max),
    // isRushEnable: yup.boolean().default(false),
    // rushFee: numberValidation(false).when('isRushEnable', {
    //   is: (val: boolean) => val,
    //   then: (schema) => schema.required(ErrorMsg('Rush Fee').required),
    // }),
    // rushEta: numberValidation(true)
    //   .integer()
    //   .when('isRushEnable', {
    //     is: (val: boolean) => val,
    //     then: (schema) => schema.required(ErrorMsg('Rush Eta').required),
    //   }),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0
          )
          return totalSize <= 36700160
        }
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        ).length

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4'
        ).length

        return imageCount + videoCount === Array.from(value).length
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728
        ) // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280
        )
      }),
    // rateModel: yup.string().required(ErrorMsg('Rate Model').required),
    // rentPeriod: yup.string().required(ErrorMsg('Billable Minimums').required),
    // rentPerPeriod: numberValidation(false).required(
    //   ErrorMsg('Price ').required
    // ),
    // rentPeriodVal: numberValidation(false).when('rentPeriod', {
    //   is: (val: string) => val === 'Others',
    //   then: (schema) => schema.required(ErrorMsg('Billable Minimums').required),
    // }),
    // OTFee: numberValidation(true),
    // DTFee: numberValidation(true),
    // rentPerDiemCost: numberValidation(true),
    // rentTravelCost: numberValidation(true),
    // rentCancellationFee: numberValidation(true),
    // rentDeliveryFee: numberValidation(false).required(
    //   ErrorMsg('Rent Delivery Fee').required
    // ),
    // rentDiscountType: yup.string().default('%'),
    // rentDiscount: numberValidation(true).when('rentDiscountType', {
    //   is: (val: string) => val === '%',
    //   then: (schema) => schema.max(100, ErrorMsg('').percentage),
    // }),
    attributes: yup
      .array()
      .of(
        yup.object().shape({
          key: yup
            .string()
            .required(ErrorMsg('Attribute name').required)
            .max(30, ErrorMsg(30).max),
          value: yup
            .string()
            .required(ErrorMsg('Description').required)
            .max(30, ErrorMsg(30).max),
        })
      )
      .test(
        'atLeastOneValue',
        'At least one value is required',
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0
          }
          // Handle other types if needed
          return false
        }
      ),
  })
  .concat(TagsSchema)
  .required()

export const BusinessSchema = yup
  .object({
    businessLocation: yup
      .object()
      .typeError(ErrorMsg('Business Location').required)
      .required(ErrorMsg('Business Location').required),
    businessType: yup
      .string()
      .required(ErrorMsg('Business Type').required)
      .default(businessType[0]),
    businessName: yup
      .string()
      .required('Business Name is required')
      .matches(
        /^[a-zA-Z0-9_\s]+$/,
        'Value can only contain letters, numbers, and underscores'
      )
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),
    department: yup
      .array()
      .min(1, 'Select at least one value')
      .required('Select at least one value'),
    businessAddress: yup
      .string()
      .required('Address is required')
      .max(500, 'Address must be at most 500 characters long'),
    businessZipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip code.')
      .required('Zip code is required.')
      .max(10, 'Invalid Zip code.'),
  })
  .required()

export const ContactInfoSchema = yup
  .object({
    firstName: yup
      .string()
      .required('First Name is required')
      .matches(/^[a-zA-Z]+$/, 'First Name should only contain letters')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    middleName: yup
      .string()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value
      })
      .matches(/^[a-zA-Z]*$/, 'Middle Name should only contain letters')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .nullable(),
    lastName: yup
      .string()
      .required('Last Name is required')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^[a-zA-Z]+$/, 'Last Name should only contain letters'),
    dob: yup
      .string()
      .test('validDateOfBirth', 'Invalid date of birth', function (value) {
        if (!value) return true // Allow empty input
        const today = new Date()
        const dob = new Date(value)
        const age = today.getFullYear() - dob.getFullYear()
        if (dob > today || age < 18) {
          return false
        }
        return true
      })
      .required('Date of birth is required'),
    zipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip code.')
      .required('Zip code is required.')
      .max(10, 'Invalid Zip code.'),
    city: yup
      .string()
      .required('City is required')
      .matches(/^[a-zA-Z\s]+$/, 'Only alphabets are allowed.')
      .max(30, 'City must be at most 30 characters long'),
    state: yup.string().required('State is required'),
    addressLineOne: yup
      .string()
      .required('Address is required')
      .max(500, 'Address must be at most 500 characters long'),
    addressLineTwo: yup
      .string()
      .max(500, 'Address must be at most 500 characters long'),
    country: yup.object().required('Country is required'),
    // business_location: yup.object().required('Country is required'),
  })
  .required()

export const LegalDocSchema = yup
  .object({
    documentType: yup.string().required('Document Type is required'),
    document: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
        (value: any) => {
          if (!value) return true // Allow empty input
          const supportedFileTypes = [
            'png',
            'jpg',
            'jpeg',
            'pdf',
            'doc',
            'docx',
            'msword',
          ]
          const fileName = value.name
          const fileExtension = fileName.slice(
            ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
          )
          return supportedFileTypes.includes(fileExtension)

          // return supportedFileTypes.includes(fileType)
        }
      )
      .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
        if (!value) return true // Allow empty input

        return value.size <= MaxFileSizeLimit
      })
      .required('Document is required'),
    documentNumber: yup
      .string()
      .required('Document Number is required')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^[a-zA-Z0-9]+$/, 'Special characters are not allowed'),
    countryOfIssue: yup.object().required('Country of issue is required'),
    dateOfExp: yup.string().required('Date of expire is required'),
  })
  .required()

export const fileSchema = yup.object({
  document: yup
    .mixed()
    .test(
      'fileType',
      'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
      (value: any) => {
        if (!value) return true // Allow empty input
        const supportedFileTypes = [
          'png',
          'jpg',
          'jpeg',
          'pdf',
          'doc',
          'docx',
          'msword',
          'vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]
        const fileType = value.type.split('/')[1] // Extract file extension from MIME type
        return supportedFileTypes.includes(fileType)
      }
    )
    .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
      if (!value) return true // Allow empty input
      return value.size <= MaxFileSizeLimit
    }),
})

export const thumbnailSchma = yup.object({
  file: yup
    .mixed()
    .test('fileType', 'Only png/jpg/jpeg file type allowed.', (value: any) => {
      if (!value) return true // Allow empty input
      const supportedFileTypes = ['png', 'jpg', 'jpeg']
      const fileType = value.type.split('/')[1] // Extract file extension from MIME type
      return supportedFileTypes.includes(fileType)
    })
    .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
      if (!value) return true // Allow empty input
      return value.size <= MaxFileSizeLimit
    }),
})

export const companyManagement = yup
  .object({
    businessName: yup
      .string()
      .required(ErrorMsg('Business Name').required)
      .matches(
        /^[a-zA-Z0-9_\s]+$/,
        'Value can only contain letters, numbers, and underscores'
      )
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),
    businessEmail: yup
      .string()
      .required(ErrorMsg('Email').required)
      .matches(
        /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/,
        'Please enter a valid email.'
      )
      .max(100, 'Email must be at most 100 characters long.')
      .email('Please enter valid email.'),
    department: yup
      .array()
      .min(1, 'Select at least one department')
      .required('Select at least one department'),
    businessDescription: yup
      .string()
      .max(500, ErrorMsg(500).max)
      .required(ErrorMsg('Description ').required),
    website: yup
      .string()
      .url('Invalid URL format. Please enter a valid website link.')
      .required(ErrorMsg('Website').required),
    establishedYear: yup
      .number()
      .typeError(ErrorMsg('Established year').required)
      .integer('Establishment year must be an integer.')
      .positive('Establishment year must be a positive number.')
      .min(1500, 'Establishment year must be greater than or equal to 1500.')
      .max(
        new Date().getFullYear(),
        'Establishment year cannot be in the future.'
      )
      .required(ErrorMsg('Established year').required),
    businessLocation: yup
      .object()
      .typeError(ErrorMsg('Business Location').required)
      .required(ErrorMsg('Business Location').required),
    businessAddress: yup
      .string()
      .required(ErrorMsg('Address').required)
      .max(500, 'Address must be at most 500 characters long'),
    businessZipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip/Postal code.')
      .required('Zip/Postal code is required.')
      .max(10, 'Invalid Zip/Postal code.'),

    logoImage: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg file type allowed.',
        (value: any) => {
          if (!value) return true // Allow empty input
          const supportedFileTypes = ['png', 'jpg', 'jpeg']
          const fileType = value.type.split('/')[1] // Extract file extension from MIME type
          const fileName = value.name
          const fileExtension = fileName.slice(
            ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
          )
          return supportedFileTypes.includes(fileExtension)

          // return supportedFileTypes.includes(fileType)
        }
      )
      .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
        if (!value) return true // Allow empty input

        return value.size <= MaxFileSizeLimit
      }),
    bgImage: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg file type allowed.',
        (value: any) => {
          if (!value) return true // Allow empty input
          const supportedFileTypes = ['png', 'jpg', 'jpeg']
          const fileType = value.type.split('/')[1] // Extract file extension from MIME type
          const fileName = value.name
          const fileExtension = fileName.slice(
            ((fileName.lastIndexOf('.') - 1) >>> 0) + 2
          )
          return supportedFileTypes.includes(fileExtension)

          // return supportedFileTypes.includes(fileType)
        }
      )
      .test('fileSize', 'Max. 3 MB file size allowed.', (value: any) => {
        if (!value) return true // Allow empty input

        return value.size <= MaxFileSizeLimit
      }),
  })
  .concat(LegalDocSchema)
  .required()

export const ProfilePersonalDetailsSchema = yup.object({
  firstName: yup
    .string()
    .required('First Name is required')
    .matches(/^[a-zA-Z]+$/, 'First Name should only contain letters')
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max),
  middleName: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value
    })
    .matches(/^[a-zA-Z]*$/, 'Middle Name should only contain letters')
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .nullable(),
  lastName: yup
    .string()
    .required('Last Name is required')
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .matches(/^[a-zA-Z]+$/, 'Last Name should only contain letters'),
})

export const ProfileAddressDetailsSchema = yup
  .object({
    zipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip code.')
      .required('Zip code is required.')
      .max(10, 'Invalid Zip code.'),
    city: yup
      .string()
      .required('City is required')
      .matches(/^[a-zA-Z\s]+$/, 'Only alphabets are allowed.')
      .max(30, 'City must be at most 30 characters long'),
    state: yup.string().required('State is required'),
    addressLineOne: yup
      .string()
      .required('Address is required')
      .max(500, 'Address must be at most 500 characters long'),
    addressLineTwo: yup
      .string()
      .max(500, 'Address must be at most 500 characters long'),
    country: yup.object().required('Country is required'),
  })
  .required()

export const UpdatePasswordSchema = yup
  .object({
    currentPassword: yup
      .string()
      .min(6, 'Password must be at least 6 characters')
      .max(16, 'Password must be less then 17 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        'Password must include at least one uppercase letter, one lowercase letter, and one numeric digit'
      )
      .required('Current Password is required'),
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters')
      .max(16, 'Password must be less then 17 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        'Password must include at least one uppercase letter, one lowercase letter, and one numeric digit'
      )
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required('Confirm Password is required'),
  })
  .required()

export const CreateCompanySchema = yup
  .object({
    name: yup
      .string()
      .required(ErrorMsg('Name').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    address: yup
      .string()
      .required(ErrorMsg('Address').required)
      .max(100, ErrorMsg(100).max),
    zipCode: yup
      .string()
      .required(ErrorMsg('ZipCode').required)
      .max(25, ErrorMsg(25).max),
    city: yup
      .string()
      .required(ErrorMsg('City').required)
      .max(50, ErrorMsg(50).max),
    country: yup.string().required(ErrorMsg('Country').required),
    state: yup
      .string()
      .max(50, ErrorMsg(50).max)
      .required(ErrorMsg('State').required),

    owner: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () =>
                yup.string().required(ErrorMsg('First Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('First Name').required)
            .max(50, ErrorMsg(50).max),
          lastName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () => yup.string().required(ErrorMsg('Last Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('Last Name').required)
            .max(50, ErrorMsg(50).max),
          email: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () => yup.string().required(ErrorMsg('Email').required),
              otherwise: () => yup.string(),
            })

            .test('mobile_number', 'Please enter a valid email', (value) => {
              if (!value) {
                return true
              } else {
                return /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}$/.test(
                  value
                )
              }
            })

            .max(100, ErrorMsg(100).max),
          phoneNo: yup
            .string()
            .test('required', 'Phone no less then 5 digits', (value) => {
              if (!value) {
                return true
              } else {
                return value.length > 4
              }
            })
            .max(13, ErrorMsg(13).max),
          countryCode: yup
            .string()

            .max(10, ErrorMsg(10).max),
          isDisabled: yup.boolean(),
        })
      )
      .test(
        'atLeastOneValue',
        'At least one value is required',
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0
          }
          // Handle other types if needed
          return false
        }
      ),

    file: yup
      .mixed()
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        ).length

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4'
        ).length

        return imageCount + videoCount === Array.from(value).length
      })
      .test(
        'imageSize',
        'Each image should be less than 10MB',
        (value: any) => {
          if (!value) return true
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('video/')
                ? true
                : file.type.startsWith('image/') && file.size <= 10485760 // 10MB in bytes
          )
        }
      )
      .test(
        'videoSize',
        'Each video should be less than 50MB',
        (value: any) => {
          if (!value) return true
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('image/')
                ? true
                : file.type.startsWith('video/') && file.size <= 52428800 // 50MB in bytes 48060415
          )
        }
      ),
  })
  .required()

export const CreateProductionSchema = yup
  .object({
    name: yup
      .string()
      .required(ErrorMsg('Name').required)
      .min(3, ErrorMsg(3).min)
      .max(25, ErrorMsg(25).max),
    description: yup
      .string()
      .max(500, ErrorMsg(500).max)
      .required(ErrorMsg('Description').required),
    type: yup.string().required('Please select Type.'),
    address: yup
      .string()
      .required(ErrorMsg('Address').required)
      .max(100, ErrorMsg(100).max),
    zipCode: yup
      .string()
      .required(ErrorMsg('ZipCode').required)
      .max(25, ErrorMsg(25).max),
    city: yup
      .string()
      .required(ErrorMsg('City').required)
      .max(50, ErrorMsg(50).max),
    country: yup.string().required(ErrorMsg('Country').required),
    state: yup
      .string()
      .max(50, ErrorMsg(50).max)
      .required(ErrorMsg('State').required),
    studio: yup.array(),
    // .min(1),
    // .required('at least one item needs to be here'),
    client: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () =>
                yup.string().required(ErrorMsg('First Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('First Name').required)
            .max(50, ErrorMsg(50).max),
          lastName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () => yup.string().required(ErrorMsg('Last Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('Last Name').required)
            .max(50, ErrorMsg(50).max),
          email: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () =>
                yup
                  .string()
                  .required(ErrorMsg('Email').required)
                  .matches(
                    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/,
                    'Please enter a valid email.'
                  ),
              otherwise: () => yup.string(),
            })

            .max(100, ErrorMsg(100).max),
          phoneNo: yup
            .string()
            .test('required', 'Phone no less then 5 digits', (value) => {
              if (!value) {
                return true
              } else {
                return value.length > 4
              }
            })
            .max(13, ErrorMsg(13).max),
          countryCode: yup
            .string()

            .max(10, ErrorMsg(10).max),
          isDisabled: yup.boolean(),
        })
      )
      .test(
        'atLeastOneValue',
        'At least one value is required',
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0
          }
          // Handle other types if needed
          return false
        }
      ),

    productionHead: yup
      .array()
      .of(
        yup.object().shape({
          firstName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () =>
                yup.string().required(ErrorMsg('First Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('First Name').required)
            .max(50, ErrorMsg(50).max),
          lastName: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () => yup.string().required(ErrorMsg('Last Name').required),
              otherwise: () => yup.string(),
            })
            // .required(ErrorMsg('Last Name').required)
            .max(50, ErrorMsg(50).max),
          email: yup
            .string()
            .when('phoneNo', {
              is: (phoneNo: any) => (phoneNo ? true : false),
              then: () =>
                yup
                  .string()
                  .required(ErrorMsg('Email').required)
                  .matches(
                    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+$/,
                    'Please enter a valid email.'
                  ),
              otherwise: () => yup.string(),
            })

            .max(100, ErrorMsg(100).max),
          phoneNo: yup
            .string()
            .test('required', 'Phone no less then 5 digits', (value) => {
              if (!value) {
                return true
              } else {
                return value.length > 4
              }
            })
            .max(13, ErrorMsg(13).max),
          countryCode: yup
            .string()

            .max(10, ErrorMsg(10).max),
          isDisabled: yup.boolean(),
        })
      )
      .test(
        'atLeastOneValue',
        'At least one value is required',
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0
          }
          // Handle other types if needed
          return false
        }
      ),

    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0
          )
          return totalSize <= 36700160
        }
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png'
        ).length

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4'
        ).length

        return imageCount + videoCount === Array.from(value).length
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728
        ) // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280
        )
      }),
  })
  .required()

const maxSize = 3 * 1024 * 1024 // 3MB in bytes

export const StudioSchema = yup
  .object({
    title: yup
      .string()
      .required(ErrorMsg('Title').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),

    file: yup
      .mixed()
      .test('required', 'You need to provide a file', (file: any) => {
        if (file?.length) return true
        return false
      })
      .test('fileSize', 'Image should be less than 3MB', (value: any) => {
        if (!value) return true
        const totalSize: any = Array.from(value).reduce(
          (acc, file: any) => acc + file.size,
          0
        )
        // console.log(totalSize <= 36700160)
        return totalSize <= maxSize
      }),
  })
  .required()

export const CategorySchema = yup
  .object({
    title: yup
      .string()
      .required(ErrorMsg('Category Name').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),

    // department: yup.string().required('Please select department.'),
    department: yup
      .array()
      .min(1, 'Please select at least 1 department.')
      .required('Please select at least 1 department.'),

    file: yup
      .mixed()
      .test('required', 'You need to provide a file', (file: any) => {
        if (file?.length) return true
        return false
      })
      .test('fileSize', 'Image should be less than 3MB', (value: any) => {
        if (!value) return true
        const totalSize: any = Array.from(value).reduce(
          (acc, file: any) => acc + file.size,
          0
        )
        // console.log(totalSize <= 36700160)
        return totalSize <= maxSize
      }),
  })
  .required()

export const RoleScehma = yup
  .object({
    title: yup
      .string()
      .required(ErrorMsg('Role name').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),

    department: yup.string().required('Please select department.'),
  })
  .required()

const maxBannerSize = 5 * 1024 * 1024 // 3MB in bytes

export const BannerSchema = yup
  .object({
    bannerTitle: yup
      .string()
      .required(ErrorMsg('Banner title').required)
      .min(3, ErrorMsg(3).min)
      .max(20, ErrorMsg(20).max),

    description: yup
      .string()
      .required(ErrorMsg('Announcement').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(500).max),

    bannerImage: yup
      .mixed()
      .test('required', 'You need to provide a file', (file: any) => {
        // console.log('FFFF=>', file)
        if (file?.length) return true
        return false
      })
      .test('fileSize', 'Max. 5 MB file size allowed', (value: any) => {
        if (!value) return true
        const totalSize: any = Array.from(value).reduce(
          (acc, file: any) => acc + file.size,
          0
        )
        // console.log(totalSize <= 36700160)
        return totalSize <= maxBannerSize
      }),
  })
  .required()
