import { useRef } from "react"

export const useCircularQueue = (size: number) => {
    const queueRef = useRef<string[]>(new Array(size).fill(''));
    const queueTop = useRef<number>(0);

    const add = (id: string) => {
        queueRef.current[queueTop.current] = id;
        queueTop.current = (queueTop.current + 1) % queueRef.current.length;
    }
    const find = (id: string) => {
        return queueRef.current.includes(id);
    }
    return { find, add, show: () => queueRef.current }
}
