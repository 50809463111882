import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ORDER_PRODUCT_TYPES, ORDER_STATUS } from '../Utils/constantData'
import { notify } from '../Utils/toastify'
import endPoints from '../api/endpoint'
import { OrderSortKeys, OrderState } from '../types'
import {
  deleteApiCall,
  getApiCall,
  patchApiCall,
  postApiCall,
  putApiCall,
} from '../api/methods'
import { logout } from './authSlice'
import store, { RootState } from './store'
import { setCommonDataLoader } from './commonSlice'
import { setProductLoader } from './products'
import OrderDetails from '../Pages/Orders/OrderDetails'
import { Dispatch } from 'react'
import { capitalizeFirstLetter } from '../Utils/helperFns'

const initialState: OrderState = {
  orders: [],
  orderDetailsLoader: false,
  error: false,
  page: 1,
  filter: null,
  searchKeywords: '',
  productOrderList: null,
  vendorlist: [],
  rentProdctsList: [],
  worksheetDetails: null,
  orderDetails: null,
  setNameList: [],
  episodeNameList: [],
  locationList: [],
  editedItem: null,
  cancleOrderModalData: null,
  addToOrderType: null,
  deliveryAddressModalData: null,
  listLoader: {
    setNames: false,
    locations: false,
    updateAddress: false,
  },
  rentalModalDetails: {
    state: false,
    itemDetails: null,
    cartId: '',
    rentPriceValue: '',
    rentalDetails: null,
  },
  quotationShifts: null,
  qoutationCartDetails: null,
  sortKey: OrderSortKeys.updatedAt,
  sortMode: -1,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderList: (state, action) => {
      const { payload }: any = action
      state.orders = payload
    },
    setVendorList: (state, action) => {
      const { payload }: any = action
      state.vendorlist = payload
    },
    setOrderLoader: (state, action) => {
      const { payload }: any = action
      state.orderDetailsLoader = payload
    },
    setOrderError: (state, action) => {
      const { payload }: any = action
      state.error = payload
    },
    setPage: (state, action) => {
      const { payload }: any = action
      state.error = payload
    },
    setSearchKeywords: (state, action) => {
      const { payload }: any = action
      state.searchKeywords = payload
    },
    setFilters: (state, action) => {
      const { payload }: any = action
      state.filter = payload
    },
    setOrderProductList: (state, action) => {
      const { payload }: any = action
      state.productOrderList = payload
    },
    setRentOrderProductList: (state, action) => {
      const { payload }: any = action
      state.rentProdctsList = payload
    },
    setWorkSheetDetails: (state, action) => {
      const { payload }: any = action
      state.worksheetDetails = payload
    },
    setOrderDetails: (state, action) => {
      const { payload }: any = action
      state.orderDetails = payload
    },
    setNameList: (state, action) => {
      const { payload }: any = action
      state.setNameList = payload
    },
    setEpisodeNameList: (state, action) => {
      const { payload }: any = action
      state.episodeNameList = payload
    },
    setLocationList: (state, action) => {
      const { payload }: any = action
      state.locationList = payload
    },
    // updateAddress: (state, action) => {
    //   const { payload }: any = action
    //   state.addressUpdate = payload
    // },
    setEditedItem: (state, action) => {
      const { payload }: any = action
      state.editedItem = payload
    },
    setCancelModalData: (
      state,
      action: PayloadAction<{
        isOrderCancle: boolean
        orderDetails?: any
      } | null>
    ) => {
      const { payload } = action
      state.cancleOrderModalData = payload
    },
    setDeliveryAddressModalStatus: (state, action: PayloadAction<any>) => {
      const { payload } = action
      state.deliveryAddressModalData = payload
    },
    updateAddToOrderType: (state, action) => {
      const { payload }: any = action
      state.addToOrderType = payload
    },
    updateListLoader: (state, action) => {
      const {
        payload: { type, value },
      }: any = action
      state.listLoader[type] = value
    },
    updateRentalModalData: (state, action: PayloadAction<any>) => {
      const { payload } = action
      state.rentalModalDetails = payload
    },
    setQuotationShifts: (state, action) => {
      const { payload }: any = action
      state.quotationShifts = payload
    },
    updateQoutationDetails: (state, action) => {
      const { payload }: any = action
      state.qoutationCartDetails = payload
    },
    updateSortKey: (state, action) => {
      const { payload }: any = action
      state.sortKey = payload
    },
    updateSortMode: (state, action) => {
      const { payload }: any = action
      state.sortMode = payload
    },
  },
})

export const {
  setOrderList,
  setOrderLoader,
  setOrderError,
  setPage,
  setSearchKeywords,
  setFilters,
  setOrderProductList,
  setRentOrderProductList,
  setWorkSheetDetails,
  setOrderDetails,
  setNameList,
  setEpisodeNameList,
  setLocationList,
  // updatePersonalAddress,
  // updateAddress,
  setEditedItem: setEditedItemStore,
  setCancelModalData,
  setDeliveryAddressModalStatus,
  updateAddToOrderType,
  updateListLoader,
  updateRentalModalData,
  setQuotationShifts,
  updateQoutationDetails,
  setVendorList,
  updateSortKey,
  updateSortMode,
} = orderSlice.actions

export default orderSlice.reducer

const handleError = (e: any) => {
  const { dispatch } = store

  if (e.data && e.data.message) {
    notify(e.data.message, 'error')
  }
  if (e.data && e.data.statusCode === 401) {
    dispatch(logout())
  }
}

export const getAndSetOrderList = (
  query: string = '?pageNo=1&limit=10',
  branchId: string | null = null
): any => {
  const { dispatch, getState } = store
  const searchKeyword = getState().order.filter?.searchKey
  const searchQuery = searchKeyword
    ? `&searchKey=${encodeURIComponent(searchKeyword)}`
    : ''
  const filters = getState().order.filter
  const {
    order: { sortKey, sortMode },
  }: RootState = getState()
  let filterQuery = ''
  if (filters) {
    if (filters.fromDate) {
      filterQuery += `&fromDate=${filters.fromDate}&toDate=${filters.toDate}`
    }
    if (filters.status) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&status=${filters.status}`
      // })
    }
    if (filters.vendorId) {
      // filters.status.forEach((status: any) => {
      filterQuery += `&vendorId=${filters.vendorId}`
      // })
    }
  }
  if (branchId) {
    filterQuery += `&branchId=${branchId}`
  }
  filterQuery += `&sortBy=${sortKey}&sortOrder=${sortMode}`

  const params = `${query}${searchQuery}${filterQuery}`
  const apiEndpoint = endPoints.order.list
  dispatch(setOrderLoader(true))
  getApiCall(
    `${apiEndpoint}${params}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        dispatch(setOrderList(data.data))
      }
      dispatch(setOrderLoader(false))
    },
    (e: any) => {
      if (e.data && e.data.message) {
        notify(e.data.message, 'error')
      }
      if (e.data && e.data.statusCode === 401) {
        dispatch(logout())
      }
      dispatch(setOrderLoader(false))
    },
    branchId ? { ['branch_id']: branchId } : {}
  )
}
export const getVendorList = (args?: any, cb?: any) => {
  console.log('newPagenewPage', args)
  return (dispatch: any, getState: any) => {
    getApiCall(
      `${endPoints.commissionManagement.vendorList}?limit=${100}&pageNo=${
        args?.pageNo || 1
      }${args?.search ? '&searchKey=' + args?.search : ''}`,

      (s: any) => {
        const {
          data: { statusCode },
          data,
        } = s
        if (statusCode && statusCode === 200) {
          // console.log('DDDDDD=>', data.data)
          dispatch(
            setVendorList(
              data?.data?.data?.map((vendor: any) => ({
                ...vendor,
                businessName: capitalizeFirstLetter(vendor.businessName),
              })) || []
            )
          )
        }
        cb && cb()
      },
      (e: any) => {
        if (e?.data && e?.data.message) {
          notify(e?.data.message, 'error')
        } else {
          notify(null, 'error')
        }
        cb && cb()
      }
    )
  }
}

export const getOrderProductsList = (
  vendorId: string,
  productType: string | null = null,
  pagination: string | null = null
) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))
  let query = `?vendorOrderId=${vendorId}`
  if (productType) {
    query += `&shoppingMode=${productType}`
  }
  if (pagination) {
    query += `&${pagination}`
  }

  getApiCall(
    `${endPoints.order.productItems}${query}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        if (productType === ORDER_PRODUCT_TYPES.buy) {
          dispatch(setOrderProductList(data.data))
        } else if (productType === ORDER_PRODUCT_TYPES.rent) {
          dispatch(setRentOrderProductList(data.data))
        } else {
          dispatch(setOrderProductList(data.data))
        }
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const getAndOrderDetails = (orderId: string) => {
  const { dispatch } = store
  dispatch(setOrderLoader(true))
  getApiCall(
    `${endPoints.order.list}/${orderId}`,
    (s: any) => {
      const { data: data } = s
      if (data.data && data.data[0]) {
        dispatch(setOrderDetails(data.data[0]))
      }
      dispatch(setOrderLoader(false))
    },
    (e: any) => {
      handleError(e)
      dispatch(setOrderLoader(false))
    }
  )
}

export const getSetNameList = (productionId: string, callBack: Function) => {
  const { dispatch } = store
  dispatch(updateListLoader({ type: 'setNames', value: true }))
  getApiCall(
    `${endPoints.order.list}/production-set-location?type=SET&id=${productionId}`,
    (s: any) => {
      const { data } = s?.data
      console.log(data, 'data set name')
      if (data) {
        if (data) {
          dispatch(setNameList(data))
        }
        if (data?.episodeList && data?.episodeList?.length) {
          dispatch(setEpisodeNameList(data?.episodeList))
        }
        callBack()
      }
      dispatch(updateListLoader({ type: 'setNames', value: false }))
    },

    (e: any) => {
      handleError(e)
      dispatch(updateListLoader({ type: 'setNames', value: false }))
    }
  )
}

export const getSetLocationList = (setId: string) => {
  const { dispatch } = store
  dispatch(updateListLoader({ type: 'locations', value: true }))

  getApiCall(
    `${endPoints.order.list}/production-set-location?type=LOCATION&id=${setId}`,
    (s: any) => {
      const {
        data: { data },
      } = s

      if (data) {
        dispatch(setLocationList(data))
      }
      dispatch(updateListLoader({ type: 'locations', value: false }))
    },
    (e: any) => {
      handleError(e)
      dispatch(updateListLoader({ type: 'locations', value: false }))
    }
  )
}

export const cancleOrder = (
  data: any,
  // id: string,
  cb: (data: boolean) => void
) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem },
  }: RootState = getState()
  dispatch(setCommonDataLoader(true))
  const cloneData = { ...data }
  console.log(cancleOrderModalData?.isOrderCancle, 'what')
  if (!cancleOrderModalData?.isOrderCancle && editedItem) {
    // cloneData['productId'] = editedItem.product.productId
    cloneData['itemId'] = editedItem.product._id
    // cloneData['vendorOrderId'] = editedItem.product.vendorOrderId
    console.log(editedItem?._id, 'Edited ID')
  }

  if (cloneData['cancelNote'].trim() == '') {
    delete cloneData['cancelNote']
  }
  // if(cancleOrderModalData?.orderDetails?._id !== cancleOrderModalData?.orderDetails?.vendorOrderId){
  // cloneData['itemId'] = cancleOrderModalData?.orderDetails?._id
  // }
  console.log(cancleOrderModalData?.orderDetails, 'Cancel Modal Order Data')
  patchApiCall(
    endPoints.order.cancleOrder(
      cancleOrderModalData?.orderDetails?.vendorOrderId
    ),
    cloneData,
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (data && statusCode && statusCode === 200) {
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(true))
    }
  )
}
export const cancleSingleOrder = (
  data: any,
  id: string,
  cb: (data: boolean) => void
) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem },
  }: RootState = getState()
  dispatch(setCommonDataLoader(true))
  const cloneData = { ...data }
  if (!cancleOrderModalData?.isOrderCancle && editedItem) {
    // cloneData['productId'] = editedItem.product.productId
    // cloneData['orderId'] = editedItem.product._id
    // cloneData['vendorOrderId'] = editedItem.product.vendorOrderId
  } else if (cancleOrderModalData) {
    const { orderDetails } = cancleOrderModalData
    // cloneData['orderId'] = orderDetails.data
    //   ? orderDetails.data.orderId
    //   : orderDetails.orderId
    // cloneData['vendorOrderId'] = orderDetails.vendorOrderId
  }
  if (cloneData['cancelNote'].trim() == '') {
    delete cloneData['cancelNote']
  }
  cloneData['itemId'] = cancleOrderModalData?.orderDetails?._id
  patchApiCall(
    endPoints.order.cancleOrder(id),
    cloneData,
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (data && statusCode && statusCode === 200) {
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(true))
    }
  )
}

export const updateQuantity = (
  data: any,
  vendorOrderId: any,
  orderId: any,
  cb: any
) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))
  patchApiCall(
    endPoints.order.updateQuantity +
      vendorOrderId +
      '/items/' +
      orderId +
      '/qty',
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 200) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateRentQuantity = (data: any, cb: any) => {
  let { qty, id } = data
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))
  putApiCall(
    endPoints.order.updateRentQuantity(id),
    { qty },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (id && statusCode && statusCode === 202) {
        notify('Qty Updated Successfully.', 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const rentApproveReject = (data: any, cb: any) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState()
  dispatch(setCommonDataLoader(true))
  const { id, status, reason, note, vendorOrderId } = data

  patchApiCall(
    endPoints.order.rentApproveReject +
      vendorOrderId +
      '/items/' +
      id +
      '/accept-reject',
    { status, ...(reason && { reason }), ...(note && { note }) },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 200) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateStatus = (data: any, id: any, orderId: any, cb: any) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))

  const { status } = data

  patchApiCall(
    `${endPoints.order.updateStatus}/${id}/items/${orderId}/status`,
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 200) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateAddressCall = (
  data: any,
  vendorOrderId: any,
  orderId: any,
  cb: any
) => {
  const { dispatch } = store
  dispatch(updateListLoader({ type: 'updateAddress', value: true }))

  patchApiCall(
    endPoints.order.updateAddress +
      vendorOrderId +
      '/items/' +
      orderId +
      '/address',
    data,
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s

      if (data && statusCode && statusCode === 200) {
        notify('Address update successfully', 'success')
        cb(true)
      }
      dispatch(updateListLoader({ type: 'updateAddress', value: false }))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(updateListLoader({ type: 'updateAddress', value: false }))
    }
  )
}

export const addProductsToOrder = (cb: (flag: boolean) => void) => {
  const { dispatch, getState } = store
  const {
    product: { selectedIds },
    order: { orderDetails },
  }: RootState = getState()
  dispatch(setCommonDataLoader(true))
  postApiCall(
    endPoints.order.addProducts(orderDetails._id),
    {
      shoppingMode: 'BUY',
      products: selectedIds.map((p) => {
        return { productId: p, isRushApply: false }
      }),
    },
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (statusCode && statusCode === 200) {
        notify('Item added successfully into order', 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const addPersonalAddress = (
  data: any,
  vendorOrderId: any,
  orderId: any,
  cb: any
) => {
  const { dispatch, getState } = store
  // dispatch(setCommonDataLoader(true))
  patchApiCall(
    endPoints.order.updateAddress +
      vendorOrderId +
      '/items/' +
      orderId +
      '/address',
    data,
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (statusCode && statusCode === 200) {
        notify('Address added successfully', 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
    }
  )
}

export const getCartId = (payload: any, cb: any) => {
  const { dispatch, getState } = store

  dispatch(setCommonDataLoader(true))
  postApiCall(
    `${endPoints.order.createCart}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s
      if (statusCode && statusCode === 200 && data && data.cartId) {
        cb(data.cartId)
      }
      cb(false)

      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateCartRentalDetails = (
  cartId: string,
  payload: any,
  cb: any
) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))
  putApiCall(
    `${endPoints.order.cartRentalDetailsUpdate(cartId)}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      if (statusCode && statusCode === 202) {
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const addToOrderRentProducts = (payload: any, cb: any) => {
  const { dispatch, getState } = store
  dispatch(setCommonDataLoader(true))
  postApiCall(
    `${endPoints.order.addItemServicesToOrder}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s
      if (statusCode && statusCode === 200) {
        // notify('Item added successfully into order', 'success')
        notify('Items added to order.', 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const createOrderQoute = (payload: any, cb: any) => {
  const { dispatch, getState } = store
  dispatch(setCommonDataLoader(true))
  postApiCall(
    `${endPoints.order.quotationCart}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s
      if (statusCode && statusCode === 200) {
        // notify('Item added successfully into order', 'success')
        notify('Order is created for qoutation.', 'success')
        cb(true, data)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateCart = (payload: any, cb: any) => {
  const { dispatch } = store

  dispatch(setCommonDataLoader(true))
  putApiCall(
    `${endPoints.order.updateCartOrDelete}`,
    payload,
    (s: any) => {
      const {
        data: { statusCode, data },
      } = s
      if (statusCode && statusCode === 200) {
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const getWorksheetDetails = (id: string, query: string) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))

  getApiCall(
    `${endPoints.order.viewWorksheet(id)}${query}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        dispatch(setWorkSheetDetails(data.data))
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const extendService = (data: any, cb: any) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState()
  // dispatch(setCommonDataLoader(true))
  const { id, startDate, endDate } = data

  putApiCall(
    endPoints.order.extend_service(id),
    { startDate, endDate },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 202) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateShift = (data: any, cb: any) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState()
  // dispatch(setCommonDataLoader(true))
  const { id, startDate, endDate } = data

  putApiCall(
    endPoints.order.updateShift(id),
    { endDate },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 202) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const updateShiftAcceptReject = (data: any, cb: any) => {
  const { dispatch, getState } = store
  const {
    order: { cancleOrderModalData, editedItem, productOrderList },
  }: RootState = getState()
  // dispatch(setCommonDataLoader(true))
  const { id, status } = data

  putApiCall(
    endPoints.order.updateStatusShift(id),
    { status },
    (s: any) => {
      const {
        data: { statusCode, message },
      } = s
      if (data && statusCode && statusCode === 202) {
        notify(message, 'success')
        cb(true)
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      cb(false)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const getQuotationWorkSheetShifts = (cartId: string, query: string) => {
  const { dispatch } = store
  dispatch(setCommonDataLoader(true))

  getApiCall(
    `${endPoints.order.quotationShifts}?cartId=${cartId}${query}`,
    (s: any) => {
      const { data: data } = s
      if (data.data) {
        dispatch(setQuotationShifts(data.data))
      }
      dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      handleError(e)
      dispatch(setCommonDataLoader(false))
    }
  )
}

export const getQuotationCartDetails = () => {
  const { dispatch, getState } = store
  const {
    chat: { selectedQuote },
  }: RootState = getState()
  if (selectedQuote?._id) {
    dispatch(setProductLoader(true))
    getApiCall(
      `${endPoints.order.quotationCartDetails}${selectedQuote?._id}?userId=${selectedQuote.createdBy}`,
      (s: any) => {
        const { data: data } = s
        if (data.data) {
          dispatch(updateQoutationDetails(data.data))
        }
        dispatch(setProductLoader(false))
      },
      (e: any) => {
        handleError(e)
        dispatch(setProductLoader(false))
      }
    )
  }
}

export const deleteCart = (query: string, loader: boolean = false) => {
  const { dispatch } = store

  loader && dispatch(setCommonDataLoader(true))
  deleteApiCall(
    `${endPoints.order.updateCartOrDelete}?${query}`,
    {},
    (s: any) => {
      const {
        data: { statusCode },
      } = s
      loader && dispatch(setCommonDataLoader(false))
    },
    (e: any) => {
      // handleError(e)
      loader && dispatch(setCommonDataLoader(false))
    }
  )
}

export const findLocationByText = async (location: string, thunkApi: any) => {
  if (!location.length) {
    return []
  }
  try {
    const apiResult = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyCu3pQ2rxalRAWIONNcxoYST8Cy7NJrveA`
    ).then((response) => response.json())

    if (apiResult) {
      const { status, results } = apiResult
      if (results && results.length) {
        return results
      }
      return []
    }
    return []
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
}
