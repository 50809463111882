import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  RequestManagementListing,
  RequestManagementQueryParameters,
  RequestModel,
  RequestManagementUserDetails,
  RequestManagementUserDetailsData,
  RequestManagementUserDetailsQueryParameters,
} from './request.type'

const initialState: RequestModel = {
  type: 0,
  requestManagementData: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
  },

  requestManagementQueryParameters: {
    pageNo: 1,
    limit: 10,
    sortOrder: 1,
    sortBy: '',
    searchKey: '',
  },

  requestManagementUserDetails: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
  },

  requestManagementUserDetailsQueryParameters: {
    pageNo: 1,
    limit: 10,
    sortOrder: 1,
    sortBy: '',
  },

  productions: undefined,
  department: undefined,
  categories: undefined,
  roles: undefined,
  loading: false,
  error: false,
  fetchFirst: false,
  requestManagementListing: [],
  requestManagementUserDetailsData: [],
  tabValue: 0,
}

const requestManagementSlice = createSlice({
  name: 'request-management',
  initialState,
  reducers: {
    setRequestManagement: (
      state: RequestModel,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },
    setRequestManagementQueryParameters: (
      state: RequestModel,
      action: PayloadAction<RequestManagementQueryParameters>
    ) => {
      state.requestManagementQueryParameters = action.payload
    },
    setRequestManagementListing: (
      state: RequestModel,
      action: PayloadAction<RequestManagementListing[]>
    ) => {
      state.requestManagementListing = action.payload
    },
    setRequestManagementUserDetailsQueryParameters: (
      state: RequestModel,
      action: PayloadAction<RequestManagementUserDetailsQueryParameters>
    ) => {
      state.requestManagementUserDetailsQueryParameters = action.payload
    },
    setrequestManagementUserDetails: (
      state: RequestModel,
      action: PayloadAction<RequestManagementUserDetailsData[]>
    ) => {
      state.requestManagementUserDetailsData = action.payload
    },
    setRequestTabValue: (
      state: RequestModel,
      action: PayloadAction<number>
    ) => {
      state.tabValue = action.payload
    },
  },
})

export const {
  setRequestManagement,
  setRequestManagementQueryParameters,
  setRequestManagementListing,
  setrequestManagementUserDetails,
  setRequestManagementUserDetailsQueryParameters,
  setRequestTabValue,
} = requestManagementSlice.actions

export default requestManagementSlice.reducer
