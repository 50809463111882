import React, { useState } from 'react'
import { Box, FormLabel, IconButton, Popover, TextField } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import { Error } from '@mui/icons-material'
import ToolTipComponent from './ToolTipComponent'
// import ToolTipComponent from '../ToolTip';
import { Controller } from 'react-hook-form'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CustomPopover from './CustomPopover'
import { charAndNumbers } from '../Utils/constantData'
interface Props {
  id: string
  label?: string
  placeholder?: string
  name: string
  type?: string
  helperText?: any
  error?: boolean
  disabled?: boolean
  // value?: string | number;
  defaultValue?: string | number
  variant?: 'outlined' | 'standard' | 'filled' | undefined
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
  value?: string
  startAdornment?: React.ReactElement | null
  endAdornment?: React.ReactElement | null
  control?: null | any
  tooltip?: boolean
  popoverContent?: React.ReactElement | null
  requiredField?: boolean
  maxLength?: any
  inputProps?: any
  onKeyPress?: any
  onKeyUp?: any
  phone?: boolean
  getOnChangeValues?: any
  readOnly?: boolean
  onBlurMethod?: false
  zipCode?: boolean
}

const InputField: React.FC<Props> = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    type,
    helperText,
    error,
    disabled,
    defaultValue,
    variant,
    value,
    onBlur,
    startAdornment,
    endAdornment,
    onChange,
    control,
    tooltip,
    popoverContent,
    onKeyDown,
    onPaste,
    requiredField,
    maxLength,
    inputProps,
    onKeyPress,
    onKeyUp,
    phone,
    getOnChangeValues,
    readOnly,
    onBlurMethod,
    zipCode,
  } = props

  const handleWheel = (event: any) => {
    // Prevent default behavior for mouse wheel events
    event.preventDefault()
  }

  // console.log(first)
  return (
    <Box>
      <FormLabel className="formLabel">
        {label} {requiredField ? <sup>*</sup> : null}
        {tooltip ? (
          <>
            <CustomPopover
              id=""
              children={popoverContent}
              arrow
              anchorOrigin_vertical="top"
              anchorOrigin_horizontal="center"
              transformOrigin_vertical={145}
              transformOrigin_horizonral={202}
            />
          </>
        ) : // <ToolTipComponent title='Your password must meet the following criteria' theme='light' arrow>
        //   <IconButton disableRipple><HelpOutlineOutlinedIcon/></IconButton>
        //    </ToolTipComponent>

        null}
      </FormLabel>
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              id={id}
              size="small"
              name={name}
              type={type}
              helperText={helperText}
              variant={variant}
              error={error}
              placeholder={placeholder}
              disabled={disabled}
              color="primary"
              onChange={(e) => {
                if (
                  e.target.value.startsWith(' ') &&
                  e.target.value.length === 1
                ) {
                  return
                }

                // if (zipCode && !charAndNumbers.test(e.target.value)) {
                //   return // Set value if it matches the regex
                // }
                // if (zipCode) {
                //   if (e.target.value.includes('-')) {
                //     return e.target.value.split('-')[0]
                //   }
                // }

                // Regular expression to prevent multiple spaces between words
                if (/\s{2,}/.test(e.target.value)) {
                  return // Prevent typing more than one space between words
                }
                if (phone && e.target.value.length >= maxLength) {
                  e.preventDefault()
                  return
                }
                // console.log('aljksdhflkjasdhflk')
                onChange(e)
                getOnChangeValues && getOnChangeValues(e)
              }}
              onWheel={handleWheel} // Add the event listener to the input
              value={value || ''}
              // value={value || ''}
              // onChange={onChange}
              inputProps={{
                ...(maxLength && { maxLength: maxLength }),
                ...(readOnly && { readOnly: readOnly }),
              }}
              fullWidth
              InputProps={{
                startAdornment,
                autoComplete: 'off',
                endAdornment: endAdornment,
                onWheel: (event: any) => event.target.blur(),
              }}
              // ref={ref}
              onBlur={onBlurMethod ? onBlurMethod : onBlur}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onPaste={onPaste}
            />
          )}
        />
      ) : (
        <TextField
          id={id}
          size="small"
          name={name}
          type={type}
          helperText={helperText}
          variant={variant}
          error={error}
          placeholder={placeholder}
          disabled={disabled}
          color="primary"
          // value={value || ''}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          fullWidth
          InputProps={
            inputProps
              ? inputProps
              : {
                  startAdornment,
                  autoComplete: 'off',
                  endAdornment: endAdornment,
                }
          }
          onKeyDown={onKeyDown}
          onPaste={onPaste}
        />
      )}
    </Box>
  )
}

InputField.defaultProps = {
  placeholder: '',
  error: false,
  disabled: false,
  variant: 'outlined',
  type: 'text',
  startAdornment: null,
  endAdornment: null,
}

export default InputField
