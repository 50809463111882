import { createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'

interface Profile {
  userData: any
  loading: boolean
  error: boolean
}

const initialState: Profile = {
  userData: {},
  loading: false,
  error: false,
}
const updateProfileReducer:any = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action: any) => {
      return { ...state, ...action.payload }
    },
    resetProfile: (state, action: any) => {
      return initialState
    },
  },
})

export const { updateProfile, resetProfile } = updateProfileReducer.actions

export default updateProfileReducer.reducer
