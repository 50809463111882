//Alt Image Text
export const AIT = (str: string = '') => {
    return {
      typeImage: `${str}`,
      checkbox: 'Checkbox',
      slider: `Slider ${str}`,
      close: 'Close',
      icon: 'Icon',
      edit: 'Edit',
      uploaderPlaceholder: 'Uploader Placeholder',
      infoIcon: 'Info',
    }
  }
  