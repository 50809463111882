import { createSlice } from '@reduxjs/toolkit'

interface PRODUCTS {
  rolesList: any
  roleDetails: any
  staticRoles: any
  // productions: any
  // countryList: any
  loading: boolean
  error: boolean
}

const initialState: PRODUCTS = {
  rolesList: {},
  staticRoles: {},
  // productions: {},
  roleDetails: {},
  // countryList: [],
  loading: true,
  error: false,
}
const RolesManagement: any = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    updateRolesManagement: (state: any, action: any) => {
      return { ...state, ...action.payload }
    },
    resetRolesManagement: (state, action: any) => {
      return initialState
    },
  },
})

export const {
  updateRolesManagement,
  resetRolesManagement,
} = RolesManagement.actions

export default RolesManagement.reducer
