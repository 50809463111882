import { PayloadAction, createSlice } from '@reduxjs/toolkit'
// import endPoints from '../api/endpoint'
// import store from './store'
// import { getApiCall } from '../api/methods'
// import { notify } from '../Utils/toastify'
// import { ProductState } from '../types'
import {
  StaticContentModel,
  StaticContentData,
  ContactData,
  FAQData,
  FAQDataQueryParameters,
  // TermsAndConditions,
  PrivacyPolicyTermsAndConditions,
  EditFAQData,
} from './static.type'

const initialState: StaticContentModel = {
  type: 0,
  staticContentData: {
    data: [],
  },

  contactData: {
    email: [''],
    contact: [
      {
        countryCode: '',
        phoneNumber: '',
      },
    ],
    address: '',
    userType: '',
    _id: '',
  },

  faqData: {
    data: [],
    total: 0,
    pageNo: 0,
    totalPage: 0,
    nextHit: 0,
    limit: 0,
  },

  editFaqData: {
    answer: '',
    question: '',
    status: '',
    userType: '',
    _id: '',
  },

  faqDataQueryParameters: {
    pageNo: 1,
    limit: 10,
    // sortOrder: 1,
    // sortBy: 'created',
  },
  privacyPolicyTermsAndConditions: {
    _id: '',
    userType: '',
    contentType: '',
    content: '',
  },

  productions: undefined,
  department: undefined,
  categories: undefined,
  roles: undefined,
  loading: false,
  error: false,
  fetchFirst: false,

  countryList: [],

  userType: 'Vendor',
}

const staticContentSlice = createSlice({
  name: 'static-content-management',
  initialState,
  reducers: {
    setStaticContentManagement: (
      state: StaticContentModel,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload
    },
    setContactUs: (
      state: StaticContentModel,
      action: PayloadAction<ContactData>
    ) => {
      state.contactData = action.payload
    },
    setFaqData: (state: StaticContentModel, action: PayloadAction<FAQData>) => {
      state.faqData = action.payload
    },
    setFaqDataQueryParameters: (
      state: StaticContentModel,
      action: PayloadAction<FAQDataQueryParameters>
    ) => {
      state.faqDataQueryParameters = action.payload
    },
    setEditFaqData: (
      state: StaticContentModel,
      action: PayloadAction<EditFAQData>
    ) => {
      state.editFaqData = action.payload
    },
    setPrivacyPolicyTermsAndConditions: (
      state: StaticContentModel,
      action: PayloadAction<PrivacyPolicyTermsAndConditions>
    ) => {
      state.privacyPolicyTermsAndConditions = action.payload
    },
    setFetchFirst: (
      state: StaticContentModel,
      action: PayloadAction<boolean>
    ) => {
      state.fetchFirst = action.payload
    },
    setType: (state: StaticContentModel, action: PayloadAction<number>) => {
      state.type = action.payload
    },

    setCountryList: (state: StaticContentModel, action: PayloadAction<any>) => {
      state.countryList = action.payload
    },

    setUserType: (state: StaticContentModel, action: PayloadAction<string>) => {
      state.userType = action.payload
    },
  },
})

export const {
  setStaticContentManagement,
  setContactUs,
  setFaqData,
  setPrivacyPolicyTermsAndConditions,
  setFetchFirst,
  setType,
  setUserType,
  setCountryList,
  setFaqDataQueryParameters,
  // setTermsAndConditions,
} = staticContentSlice.actions

export default staticContentSlice.reducer
