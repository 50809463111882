import { Suspense } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { pageRoutes } from './routes'
import { routeTypes } from '../types'
import PrivateRoute from './customRoute'
import Header from '../Containers/Header'
import Footer from '../Containers/Footer'
import { RootState, useAppSelector } from '../redux/store'
import Loading from '../Components/Loading'
import NotificationService from '../Components/NotificationComponent'

function RoutesWrapper() {
  const { status } = useAppSelector((state: RootState) => state.auth)
  return (
    <Suspense fallback={<Loading type='div' height='full' pageLoading={true} className='routerContainer'/>}>
      <Routes>
        <Route path="/" element={<>
          <NotificationService />
          <Outlet />
        </>}>
          {pageRoutes.map((route: routeTypes) => {
            if (route.isPrivate) {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={
                    <PrivateRoute isLoggedIn={status}>
                      <div className="main">
                        <Header />
                        <div className="mainContainer">
                          <route.Component {...route.pageProp} />
                        </div>
                        <Footer />
                      </div>
                    </PrivateRoute>
                  }
                />
              )
            }
            return (
              <Route
                key={route.id}
                path={route.path}
                element={<route.Component {...route.pageProp} />}
              />
            )
          })}
          <Route path="*" element={<div>Error Screen here</div>} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default RoutesWrapper

// export const router = createBrowserRouter([
//   {
//     id: 'root',
//     path: '/',
//     Component: AuthLayout,
//     children: [
//       {
//         index: true,
//         Component: PublicPage,
//       },
//       {
//         path: 'login',
//         Component: Login,
//       },
//       {
//         path: 'register',
//         Component: Register,
//       },
//       {
//         path: 'verify',
//         Component: VeriftEmail,
//       },
//     ],
//   },
// ])
