import { lazy } from 'react'
import { ROUTES } from '../helpers/contants'
import { routeTypes } from '../types'
import Welcome from '../Pages/Register/Welcome'
import BusinessInfo from '../Pages/Register/BusinessInfo'
import Users from '../Pages/Users'

// import ProductManagement from '../Pages/ProductManagement'
const DashboardWrapper = lazy(() => import('../Pages/Dashboard'))
const AuthLayout = lazy(() => import('../Containers/AuthLayout'))
const VendorManagement = lazy(() => import('../Pages/VendorManagement'))
const Orders = lazy(() => import('../Pages/Orders'))
const StaticContent = lazy(() => import('../Pages/StaticContent'))
const AddFaq = lazy(() => import('../Pages/StaticContent/components/AddFaq'))

const Financials = lazy(() => import('../Pages/Financials'))
const Notifications = lazy(() => import('../Pages/Notifications'))
const Banners = lazy(() => import('../Pages/Banner'))
const Clients = lazy(() => import('../Pages/Clients'))
const USERS = lazy(() => import('../Pages/Users'))
const EditProfile = lazy(() => import('../Pages/Profile'))
const ProductManagement = lazy(() => import('../Pages/ProductManagement'))

const AddProduct = lazy(() => import('../Pages/ProductManagement/AddProduct'))
const AddServices = lazy(() => import('../Pages/ProductManagement/AddServices'))
const EditUserProfile = lazy(() => import('../Pages/Users/components/EditUser'))

const Roles = lazy(() => import('../Pages/RolesAndResponsibilities'))
const CompanyManagement = lazy(() => import('../Pages/StudioManagement'))

// import AddProduct from '../Pages/ProductManagement/AddProduct'
// import AddServices from '../Pages/ProductManagement/AddServices'
const ManageProduction = lazy(() => import('../Pages/ManageProduction'))
const RequestManagement = lazy(()=> import('../Pages/RequestManagement'))
const Chats = lazy(() => import('../Pages/Chats'))
const Commissions = lazy(() => import('../Pages/CommissionsManagement'))

export const pageRoutes: routeTypes[] = [
  {
    id: 1,
    name: 'Dashboard',
    path: ROUTES.DASHBOARD,
    Component: DashboardWrapper,
    isPrivate: true,
  },
  {
    id: 2,
    name: 'Login',
    path: ROUTES.LOGIN,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Login' },
  },
  {
    id: 3,
    name: 'SignUp',
    path: ROUTES.SIGNUP,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'SignUp' },
  },
  {
    id: 4,
    name: 'Verify',
    path: ROUTES.VERIFY,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Verify' },
  },
  {
    id: 5,
    name: 'Forgot',
    path: ROUTES.FORGOT_PASSWORD,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Forgot' },
  },
  {
    id: 6,
    name: 'Reset',
    path: ROUTES.RESET_PASSWORD,
    Component: AuthLayout,
    isPrivate: false,
    pageProp: { page: 'Reset' },
  },
  {
    id: 6,
    name: 'Welcome',
    path: ROUTES.WELCOME,
    Component: Welcome,
    isPrivate: true,
  },
  {
    id: 7,
    name: 'BusinessInfo',
    path: ROUTES.BUSINESSINFO,
    Component: BusinessInfo,
    isPrivate: true,
  },
  {
    id: 8,
    name: 'Verdors',
    path: ROUTES.VENDORS,
    Component: VendorManagement,
    isPrivate: true,
    pageProp: { page: 'VerdorsList' },
  },
  {
    id: 9,
    name: 'Products',
    path: `${ROUTES.VENDOR_PROFILE}/:vendorId`,
    Component: VendorManagement,
    isPrivate: true,
    pageProp: { page: 'VerdorsProfile' },
  },
  {
    id: 10,
    name: 'Orders',
    path: ROUTES.ORDERS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'Orders' },
  },
  {
    id: 11,
    name: 'StaticContent',
    path: ROUTES.STATIC_CONTENT,
    Component: StaticContent,
    isPrivate: true,
    pageProp: { page: 'StaticContent' },
  },
  {
    id: 12,
    name: 'Financials',
    path: ROUTES.FINANCIALS,
    Component: Financials,
    isPrivate: true,
    pageProp: { page: 'Financials' },
  },
  {
    id: 13,
    name: 'Notifications',
    path: ROUTES.NOTIFICATIONS,
    Component: Notifications,
    isPrivate: true,
    pageProp: { page: 'Notifications' },
  },
  {
    id: 14,
    name: 'Banner',
    path: ROUTES.BANNER,
    Component: Banners,
    isPrivate: true,
    pageProp: { page: 'Banner' },
  },
  {
    id: 15,
    name: 'Clients',
    path: ROUTES.CLIENTS,
    Component: Clients,
    isPrivate: true,
    pageProp: { page: 'Clients' },
  },
  {
    id: 16,
    name: 'Users',
    path: ROUTES.USERS,
    Component: USERS,
    isPrivate: true,
    pageProp: { page: 'Users' },
  },
  {
    id: 17,
    name: 'Users',
    path: ROUTES.USERS,
    Component: USERS,
    isPrivate: true,
    pageProp: { page: 'UserProfile' },
  },
  {
    id: 18,
    name: 'UserProfile',
    path: ROUTES.USERS_PROFILE,
    Component: USERS,
    isPrivate: true,
    pageProp: { page: 'UserProfile' },
  },
  {
    id: 19,
    name: 'AddNotifications',
    path: ROUTES.ADD_NOTIFICATIONS,
    Component: Notifications,
    isPrivate: true,
    pageProp: { page: 'AddNotification' },
  },
  {
    id: 20,
    name: 'Banner',
    path: ROUTES.ADD_BANNER,
    Component: Banners,
    isPrivate: true,
    pageProp: { page: 'AddBanner' },
  },
  {
    id: 21,
    name: 'EditProfile',
    path: ROUTES.EDIT_PROFILE,
    Component: EditProfile,
    isPrivate: true,
    pageProp: { page: 'EditProfile' },
  },
  {
    id: 21,
    name: 'EditProfileVerify',
    path: ROUTES.EDIT_PROFILE_VERIFY,
    Component: EditProfile,
    isPrivate: true,
    pageProp: { page: 'EditProfileVerify' },
  },
  {
    id: 22,
    name: 'StaticContent',
    path: `${ROUTES.ADD_FAQ}/:userType`,
    Component: AddFaq,
    isPrivate: true,
    pageProp: { page: 'AddFaq' },
  },

  {
    id: 23,
    name: 'Banner',
    path: ROUTES.ADD_VENDOR_BANNER,
    Component: Banners,
    isPrivate: true,
    pageProp: { page: 'add-vendor-banner' },
  },
  {
    id: 24,
    name: 'EditFaq',
    path: `${ROUTES.EDIT_FAQ}/:userType`,
    Component: AddFaq,
    isPrivate: true,
    pageProp: { page: 'AddFaq' },
  },
  {
    id: 25,
    name: 'ProductManagement',
    path: ROUTES.PRODUCT_MANAGEMENT,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'ProductList' },
  },
  {
    id: 26,
    name: 'ProductManagement',
    path: ROUTES.ADD_PRODUCTS,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddProduct' },
  },
  {
    id: 27,
    name: 'ProductManagement',
    path: ROUTES.ADD_SERVICES,
    Component: ProductManagement,
    isPrivate: true,
    pageProp: { page: 'AddService' },
  },
  {
    id: 28,
    name: 'RolesAndResponsibilities',
    path: ROUTES.ROLES,
    Component: Roles,
    isPrivate: true,
    pageProp: { page: 'RolesList' },
  },
  {
    id: 29,
    name: 'RolesAndResponsibilities',
    path: ROUTES.CREATE_DETAILS,
    Component: Roles,
    isPrivate: true,
    pageProp: { page: 'CreateRole' },
  },
  {
    id: 30,
    name: 'ManageProduction',
    path: ROUTES.MANAGE_PRODUCTION,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'ManageProduction' },
  },
  {
    id: 31,
    name: 'ManageProduction',
    path: ROUTES.CREATE_PRODUCTION,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'CreateProduction' },
  },
  // {
  //   id: 31,
  //   name: 'ManageProduction',
  //   path: `${ROUTES.EDIT_PRODUCTION}`,
  //   Component: ManageProduction,
  //   isPrivate: true,
  //   pageProp: { page: 'ManageProduction' },
  // },
  {
    id: 32,
    name: 'ManageProduction',
    path: `${ROUTES.PRODUCTION_DETAIL}/:productionId`,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'ProductionDetail' },
  },
  {
    id: 33,
    name: 'ManageProduction',
    path: `${ROUTES.EPISODE_DETAIL}/:episodeId`,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'EpisodeDetail' },
  },
  {
    id: 33,
    name: 'ManageProduction',
    path: `${ROUTES.SET_DETAIL}/:setId`,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'SetDetail' },
  },
  {
    id: 34,
    name: 'ManageProduction',
    path: `${ROUTES.LOCATION_DETAIL}/:locationId`,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'LocationDetail' },
  },
  {
    id: 35,
    name: 'ManageProduction',
    path: ROUTES.DEPARTMENT_DETAIL,
    Component: ManageProduction,
    isPrivate: true,
    pageProp: { page: 'DepartmentDetail' },
  },

  {
    id: 36,
    name: 'CompanyManagement',
    path: ROUTES.COMPANY_MANAGEMENT,
    Component: CompanyManagement,
    isPrivate: true,
    pageProp: { page: 'CompanyManagement' },
  },
  {
    id: 37,
    name: 'CompanyManagement',
    path: ROUTES.CREATE_COMPANY,
    Component: CompanyManagement,
    isPrivate: true,
    pageProp: { page: 'CreateCompany' },
  },
  {
    id: 38,
    name: 'RequestManagement',
    path: ROUTES.REQUESTS,
    Component: RequestManagement,
    isPrivate: true,
    pageProp: { page: 'RequestList' },
  },
  {
    id: 39,
    name: 'RequestManagement',
    path: `${ROUTES.VIEW_REQUEST}/:userId`,
    Component: RequestManagement,
    isPrivate: true,
    pageProp: { page: 'ViewRequest' },
  },
  {
    id: 34,
    name: 'Order Management',
    path: ROUTES.ORDERS_DETAILS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderDetails' },
  },
  {
    id: 33,
    name: 'Order Management',
    path: ROUTES.ORDERS_WORKSHEET,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderWorksheet' },
  },
  {
    id: 34,
    name: 'Order Management',
    path: ROUTES.ORDERS_PRODUCTS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'OrderProducts' },
  },
  {
    id: 35,
    name: 'Order Management',
    path: ROUTES.ADD_PRODUCTS_ORDERS,
    Component: Orders,
    isPrivate: true,
    pageProp: { page: 'AddProducts' },
  },
  {
    id: 36,
    name: 'Chats',
    path: ROUTES.CHATS,
    Component: Chats,
    isPrivate: true,
    pageProp: { page: 'Chats' },
  },
  {
    id: 37,
    name: 'Commissions',
    path: ROUTES.COMMISSIONS,
    Component: Commissions,
    isPrivate: true,
    pageProp: { page: 'commissions' },
  },
  {
    id: 38,
    name: 'Commissions',
    path: ROUTES.COMMISSIONS_EDIT,
    Component: Commissions,
    isPrivate: true,
    pageProp: { page: 'editCommissions' },
  }
]
