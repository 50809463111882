export const ROUTES = {
  LOGIN: '/login',
  VERIFY: '/verify',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/resetPassword',
  SIGNUP: '/signup',
  DASHBOARD: '/',
  WELCOME: '/welcome',
  BUSINESSINFO: '/business-info',
  VENDORS: '/vendors',
  VENDOR_PROFILE: '/vendor-profile',
  ORDERS: '/orders',
  STATIC_CONTENT: '/static-content',
  ADD_FAQ: '/add-faq',
  FINANCIALS: '/financials',
  NOTIFICATIONS: '/notifications',
  ADD_NOTIFICATIONS: '/add-notifications',
  BANNER: '/banners',
  ADD_BANNER: '/add-banner',
  ADD_VENDOR_BANNER: '/add-vendor-banner',
  CLIENTS: '/clients',
  USERS: '/users',
  USERS_PROFILE: '/user-profile',
  EDIT_PROFILE: '/edit-profile',
  EDIT_PROFILE_VERIFY: '/verify-otp',
  EDIT_FAQ: '/edit-faq',
  PRODUCT_MANAGEMENT: '/product-management',
  ADD_PRODUCTS: '/add-product',
  ADD_SERVICES: '/add-service',
  EDIT_USER_PROFILE: '/edit-user',
  ROLES: '/roles',
  CREATE_DETAILS: '/role-details',
  MANAGE_PRODUCTION: '/productions',
  CREATE_PRODUCTION: '/create-production',
  PRODUCTION_DETAIL: '/production-detail',
  EPISODE_DETAIL: '/episode-detail',
  SET_DETAIL: '/set-detail',
  LOCATION_DETAIL: '/location-detail',
  DEPARTMENT_DETAIL: '/department-detail',
  // EDIT_PRODUCTION: '/edit-production',
  COMPANY_MANAGEMENT: '/company-management',
  CREATE_COMPANY: '/create-company',
  REQUESTS: '/requests',
  VIEW_REQUEST: '/view-request',
  ORDERS_DETAILS: '/order-details/:id',
  ORDERS_WORKSHEET: '/order-worksheet',
  ORDERS_PRODUCTS: '/order-products',
  ADD_PRODUCTS_ORDERS: '/add-products-order',
  ORDER_SERVICES: '/chats/orders',
  CREATE_ORDER: '/chats/create-order',
  CHATS: '/chats',
  COMMISSIONS: '/commissions',
  COMMISSIONS_EDIT: '/edit-commissions'
}

export const standardErrorMsg: string = 'Something went wrong!'

export const ErrorMsg = (value: number | string) => {
  return {
    min: `Min. ${value} Characters allowed.`,
    max: `Max. ${value} Characters allowed`,
    required: `${value} is required.`,
    onlyLetter: `${value} should only contain letters`,
    email: `Please enter valid email address.`,
    string: ``,
    typeError: `Must be a Number`,
    positive: `Must be a positive Number`,
    integer: `Must be an Integer`,
    array: ``,
  }
}
export const MaxFileSizeLimit = 3 * 1024 * 1024



export enum PRODUCT_TYPES {
  purchase = 'Purchase',
  rental = 'Rental',
  rentAndPurchase = 'Rental/Purchase',
}

export enum BACKEND_P_TYPES {
  PURCHASE = 'PURCHASE',
  RENTAL = 'RENTAL',
  RENTAL_AND_PURCHASE = 'RENTAL_AND_PURCHASE',
}

export const allowedImageMimeType = [
  'image/jpeg', //.jpg | .jpeg
  'image/png', // .png
  'image/gif', // .gif
  'image/svg+xml', // .svg,
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.svg',
  '.heic',
]
export const allowedAudioMimeType = [
  'audio/aac', // .aac
  'audio/mp4', // .m4a
  'audio/x-wav', // .wav .wave
  'audio/x-aiff', //.aff .aif .aiff
  'application/ogg', // .ogg
  'audio/mpeg', // .ogg,
  '.ogg',
  '.aff',
  '.aif',
  '.aiff',
  '.wav',
  '.wave',
  '.m4a',
  '.aac',
]
export const allowedVideoMimeType = [
  'video/x-ms-wmv', // .wmv
  'video/avi', // .avi
  'video/quicktime', // .mov
  'video/mp4', // .mp4
  'video/x-flv', // .flv
  '.flv',
  '.mp4',
  '.mov',
  '.avi',
  '.wmv',
  '.heic',
]
export const allowedDocsMimeType = [
  'application/pdf', //.pdf
  'application/vnd.ms-excel', // MS Excel File (.xlsx,. xls)
  '.pdf',
  '.xls',
]
export const allMediaMimeType = [
  ...allowedDocsMimeType,
  ...allowedAudioMimeType,
  ...allowedImageMimeType,
  ...allowedVideoMimeType,
]
export const CHAT_ACTIVE = 'ACTIVE'
export const CHAT_DELETED = 'DELETED'
export const CHAT_UPLOADING = 'UPLOADING'
export const CHAT_FAILED = 'FAILED'
export const CHAT_ORDER_COMPLETED = 'ORDER_COMPLETED'
export const MEDIA_TYPES = ['IMAGE', 'MEDIA', 'DOCS', 'VIDEO']
export const USER_ADMIN_CHAT = 'user_admin_chat'
export const VENDOR_ADMIN_CHAT = 'vendor_admin_chat'
export const DEFAULT_URL = ["https://app-development.s3.amazonaws.com/ReelProject/1701757389017user%20%281%29.png", "https://app-development.s3.amazonaws.com/ReelProject/1709702563475_User_Placeholder.png"];
export const DEFAULT_ADMIN_ID = "66705d5d0b99f01dd013ab7c";
// 100 MB
export const MAX_FILE_SIZE_CHAT = 100 * 1024 * 1024;
// 10 files
export const MAX_FILES = 10;
export const USER_TYPE_MAP = {
  [VENDOR_ADMIN_CHAT]: "VENDOR",
  [USER_ADMIN_CHAT]: "USER",
}