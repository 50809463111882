import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { notify } from '../Utils/toastify';
import { NotificationFCM } from '../types';
import { ToastNotification } from './ToastNotification';
import { useSocket } from '../Pages/Chats/SocketContext';
import { ROUTES } from '../helpers/contants';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { fetchNoticationList } from '../redux/notification.slice';
import endPoints from '../api/endpoint';
import { putApiCall } from '../api/methods';
import { RootState, useAppSelector } from '../redux/store';
const VAPID_KEY = process.env.FIREBASE_WEBPUSH_VAPID;

const NotificationService = () => {
    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: process.env.FIREBASE_API_KEY,
        authDomain: process.env.FIREBASE_DOMAIN,
        projectId: process.env.FIREBASE_PROJECT_ID,
        storageBucket: process.env.FIREBASE_BUCKET,
        messagingSenderId: process.env.FIREBASE_SENDER_ID,
        appId: process.env.FIREBASE_APP_ID,
    };
    const [token, setToken] = useState<string>('');
    const { userData, status } = useAppSelector((state: RootState) => state.auth);
    const socketClient = useSocket();
    const navigate = useNavigate();
    initializeApp(firebaseConfig);


    // const messaging = getMessaging(firebaseApp);
    let messaging: any = null;
    try {
        messaging = getMessaging();
    } catch (err) {
        console.error('Failed to initialize Firebase Messaging', err);
    }

    // handle message
    function handleMessage(payload: NotificationFCM) {
        if (payload.notification?.title) {
            try {
                let notificationHandler: () => void = () => {
                    console.log('default-handlers');
                };
                switch (payload.data?.notificationType) {
                    case 'CHAT': {
                        notificationHandler = () => {
                            if (socketClient) {
                                const chatId = payload.data?.chatId;
                                if (chatId) {
                                    socketClient.activeChat({ chatId });
                                    navigate(ROUTES.CHATS);
                                }
                            }
                        };
                        break;
                    }
                    case 'ORDER': {
                        notificationHandler = () => {
                            navigate(
                                `${payload.data?.vendorOrderId
                                    ? ROUTES.ORDERS_DETAILS.replace(
                                        ':id',
                                        payload.data?.vendorOrderId
                                    )
                                    : ROUTES.ORDERS
                                }`
                            );
                        };
                        break;
                    }
                    default: {
                        break;
                    }
                }
                notify(
                    <ToastNotification
                        title={payload.notification?.title}
                        text={payload?.notification?.body || ''}
                        onClick={() => {
                            notificationHandler();
                        }}
                    />,
                    'success'
                );
            } catch (error) {
                console.log(error);
            }
            // dispatch(fetchNoticationList({ page: 1, pageSize: 5 }));
        } else {
            console.log('no-title');
        }
    }
    // register function
    new Promise((resolve) => {
        messaging && onMessage(messaging, (payload: any) => {
            resolve(payload);
            console.log('notificagion', payload);
        });
    }).then((payload) => {
        handleMessage(payload as NotificationFCM);
    });

    // get notification permission
    const getPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const localToken = localStorage.getItem('tokenFCM');
            const token = localToken ||
                (messaging && await getToken(messaging, {
                    vapidKey: VAPID_KEY,
                }));
            localStorage.setItem('tokenFCM', token);
            //We can send token to server
            setToken(token);
        } else if (permission === 'denied') {
            //notifications are blocked
            console.log('You denied for the notification');
        }
    };
    // run on render
    useEffect(() => {
        if ('serviceWorker' in navigator && !('safari' in window)) {
            // Register the service worker as soon as the app loads
            // socpe
            navigator.serviceWorker
                .register('../../../firebase-messaging-sw.js', {
                    scope: 'firebase-cloud-messaging-push-scope',
                })
                .then((reg) => {
                    var serviceWorker;
                    if (reg.installing) {
                        serviceWorker = reg.installing;
                        // console.log('Service worker installing');
                    } else if (reg.waiting) {
                        serviceWorker = reg.waiting;
                        // console.log('Service worker installed & waiting');
                    } else if (reg.active) {
                        serviceWorker = reg.active;
                        // console.log('Service worker active');
                    }

                    if (serviceWorker) {
                        console.log('sw: current state', serviceWorker.state);
                        if (serviceWorker.state == 'activated') {
                            //If push subscription wasnt done yet have to do here
                            console.log('sw: already activated - Do watever needed here');
                            getPermission();
                        }
                        serviceWorker.addEventListener('statechange', function (e: any) {
                            console.log('sw: statechange : ', e.target.state);
                            if (e.target.state == 'activated') {
                                console.log(
                                    'sw: Just now activated. now we can subscribe for push notification'
                                );
                                if (!('PushManager' in window)) {
                                    console.log("sw: Push messaging isn't supported.");
                                    return;
                                }
                                setTimeout(() => {
                                    getPermission();
                                }, 200);
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log('sw: Service worker registration failed, error:', err);
                });
        }
    }, []);
    useEffect(() => {
        if (status && (userData._id || userData.userId)) {
            if (token) {
                putApiCall(
                    endPoints.notification.updateDeviceTokenFCM + `?token=${token}`,
                    {},
                    () => {
                        console.log('token-added, now can receieve notificaiton');
                    },
                    () => {
                        console.log('error-in updating notification');
                    }
                );
            }
        }
    }, [status, userData, token]);
    return null;
};
export default NotificationService;
