// import Images from "../Utils/images";

// interface Props {
//     type?: 'div' | 'table'
//     tableColumns?: number
//     height?: 'full' | 'auto'
//     overlay?: boolean
//   }
// export default function Loading(props:Props){
//     const { type, tableColumns, height, overlay } = props
//     const width: number = window.innerWidth;
//     return(
//         <div className="loading">
//             <img src={Images.LOADER_TWO} alt="Loading" />
//         </div>
//     )
// }

import { AIT } from '../Utils/Localization'
import Images from '../Utils/images'

interface Props {
  type?: 'div' | 'table'
  tableColumns?: number
  height?: 'full' | 'auto'
  overlay?: boolean
  pageLoading?: boolean
  className?: string
}
export default function Loading(props: Props) {
  const { type, tableColumns, height, overlay, pageLoading, className } = props
  const width: number = window.innerWidth;
  return type === 'div' ? (
    <div className={`loading ${height === 'full' ? 'fullHeight' : ''} ${overlay ? 'loading_overlay' : ''} ${pageLoading ? 'pageLoading' : ''} ${className}`}>
      <img src={Images.LOADER_TWO} alt={"Loading"} />
    </div>
  ) : (
    <>
      {
        width > 600 ?
          // <tbody>
          <tr>
            <td align='center' colSpan={tableColumns}>
              <div className='loading'>
                <img src={Images.LOADER_TWO} alt={"Loading"} />
              </div>
            </td>
          </tr>
          // </tbody>
          :
          <div className={`loading ${height === 'full' ? 'fullHeight' : ''}`}
            style={{
              width: `${width}px`
            }}
          >
            <img src={Images.LOADER_TWO} alt={"Loading"} />
          </div>
      }
    </>
  )
}
